import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AuthProvider } from 'oidc-react';

import AuthenticatedApp from './AuthenticatedApp';
import { resetUser, setUser } from '../common/reducers/authentication';

const REDIRECT_URI_STORAGE_KEY = 'urlBeforeSignIn';
export default function Authentication() {
  const dispatch = useDispatch();
  const [error, setError] = useState<Error>();

  function signOut() {
    dispatch(resetUser());
  }

  return (
    <AuthProvider
      authority={import.meta.env.VITE_FEDID_URL}
      clientId={import.meta.env.VITE_FEDID_CLIENT_ID}
      scope="openid profile"
      redirectUri={encodeURI(`${window.location.origin}/callback`)}
      onBeforeSignIn={() => {
        sessionStorage.setItem(REDIRECT_URI_STORAGE_KEY, window.location.href);
      }}
      onSignIn={(user) => {
        dispatch(setUser({ user }));
        const redirectUri = sessionStorage.getItem(REDIRECT_URI_STORAGE_KEY);
        if (redirectUri) {
          sessionStorage.removeItem(REDIRECT_URI_STORAGE_KEY);
          window.location.replace(redirectUri);
        }
      }}
      onSignOut={signOut}
      onSignInError={setError}
    >
      <AuthenticatedApp error={error} />
    </AuthProvider>
  );
}
