import i18next from 'i18next';
const i18n = i18next;

export default class ModelTableSortMethod {
  public readonly value: string;

  public readonly i18nKey: string;

  public readonly isDefault: boolean;

  constructor(value: string, i18nKey: string, isDefault = false) {
    this.value = value;
    this.i18nKey = i18nKey;
    this.isDefault = isDefault;
  }

  public get label(): string {
    return i18n.t(`application.${this.i18nKey}`);
  }
}
