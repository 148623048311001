import i18next from 'i18next';
const i18n = i18next;
import { VpWifiOnIcon } from '@vtmn-play/icons/react';

import { generateParams } from 'common/utils/navigation';
import ModelFilters from 'common/models/ModelFilters';
import { IModelTable } from 'common/models/interfaces/table';
import ModelToolbar from 'common/models/ModelToolbar';
import { IPageParams } from 'common/models/interfaces/popup-selector';

const getLinkBySelectors = (
  filters: ModelFilters,
  table: IModelTable,
  toolbar: ModelToolbar,
  pageParams: IPageParams,
) => `/live${generateParams(filters, table, toolbar, pageParams)}`;

export default {
  id: 'live',
  title: () => i18n.t(`navigation.label.realtime`),
  icon: (
    <VpWifiOnIcon
      style={{
        fontSize: '3rem',
        width: '30px',
        height: '38px',
        marginLeft: '-6px',
      }}
    />
  ),
  order: 1,
  link: getLinkBySelectors,
  paths: ['/live'],
  subLinks: null,
  isNotAccessibleForFranchised: false,
};
