import i18next from 'i18next';
const i18n = i18next;
import { isEqual } from 'lodash';

import DateUtils from 'common/utils/date';
import TypeDateComparableType from 'common/models/enums/types/date-type-comparable';
import ModelDateTypeComparable from 'common/models/enums/models/date-type-comparable';
import dayjs, { Dayjs } from 'dayjs';

type FunctionDate = (from?: Dayjs) => Dayjs;

export default class ModelDateRange {
  public readonly value: string;

  public readonly dateTypeComparable;

  private readonly lastYearMethod: FunctionDate;

  private readonly localStorageValueWithDateRange: boolean;

  public readonly weight: number;

  constructor({
    value,
    label,
    shortLabel,
    from,
    to = null,
    dateTypeComparable = null,
    weight = null,
    getToLastYear = null,
    localStorageValueWithDateRange = true,
  }: {
    value: string;
    label: string;
    shortLabel: string;
    from: FunctionDate;
    to?: FunctionDate;
    dateTypeComparable?: any;
    weight?: any;
    getToLastYear?: FunctionDate;
    localStorageValueWithDateRange?: boolean;
  }) {
    this.value = value;
    this.keyI18n = label;
    this.keyI18nShortLabel = shortLabel;
    this.fromMethod = from;
    this.toMethod = to;
    this.dateTypeComparable = dateTypeComparable;
    this.weight = weight;
    this.lastYearMethod = getToLastYear;
    this.localStorageValueWithDateRange = localStorageValueWithDateRange;
  }

  private readonly keyI18n: string;

  public get label(): string {
    return i18n.t(this.keyI18n);
  }

  private readonly keyI18nShortLabel: string;

  public get shortLabel(): string {
    return i18n.t(this.keyI18nShortLabel);
  }

  private readonly fromMethod: FunctionDate;

  public get from(): Dayjs {
    return this.fromMethod();
  }

  private readonly toMethod: FunctionDate;

  public get to(): Dayjs {
    return this.toMethod?.();
  }

  public get labelKey(): string {
    return this.keyI18n;
  }

  public get shortLabelKey(): string {
    return this.keyI18nShortLabel;
  }

  public get localStorageValue(): string {
    if (this.localStorageValueWithDateRange) {
      const rangeDateValue = `${DateUtils.toDateString(
        this.from,
      )}:${DateUtils.toDateString(this.to)}`;
      return `${this.value}:${rangeDateValue}`;
    }

    return this.value;
  }

  public toString(): string {
    return (
      DateUtils.toHumanDateString(this.fromMethod()) +
      (this.toMethod &&
      this.toMethod() !== null &&
      !this.toMethod().isSame(this.fromMethod())
        ? ` - ${DateUtils.toHumanDateString(this.toMethod())}`
        : '')
    );
  }

  public isEqual(dateRange: ModelDateRange): boolean {
    return dateRange && isEqual(dateRange.toString(), this.toString());
  }

  public getLastYearTo(
    fromLastYear: Dayjs,
    comparableDateType: ModelDateTypeComparable,
  ): Dayjs {
    if (this.toMethod && this.toMethod !== null && this.toMethod() !== null) {
      if (this.lastYearMethod !== null) {
        return this.lastYearMethod(fromLastYear);
      }
      if (comparableDateType === TypeDateComparableType.CALENDAR_DATE) {
        // On récupère directement l'année - 1
        return this.toMethod().subtract(1, 'year');
      }
      // On fait la différence entre la comparabilité demandé
      // On l'ajoute à la date de départ
      const diff = this.toMethod().diff(this.fromMethod());
      return dayjs(fromLastYear).add(diff);
    }
    return null;
  }
}
