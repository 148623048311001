import { compact, find, isEmpty, isEqual, map, toInteger } from 'lodash';
import { SLIDER_DEFAULT_VALUE } from 'common/constants/slider';
import ModelTypeTreeLevel from 'common/models/enums/models/type-tree-level';
import TypeTreeLevel from 'common/models/enums/types/type-tree-level';
import ModelHierarchyCountry, {
  DEFAULT_SELECTOR_CODE_COUNTRY,
  DEFAULT_SELECTOR_HIERARCHY_COUNTRY,
} from 'common/models/hierarchies/ModelHierarchyCountry';
import ModelHierarchyStore from 'common/models/hierarchies/ModelHierarchyStore';
import ModelProductHierarchy from 'common/models/hierarchies/products/product-hierarchy';
import { IProfiles } from 'common/models/interfaces/profiles';
import { ModelToolbarLocalStorage } from 'common/models/interfaces/toolbar';
import DropValue, { OptionnalDropValue } from 'common/models/types/drop-value';
import {
  getValueOrDefaultOrDrop,
  getValueOrDefaultOrEmptyArray,
} from 'common/utils/global';
import { IPageParams } from 'common/models/interfaces/popup-selector';
import { WORLD_HIERARCHY_ID } from 'common/constants/world-hierarchy';

class ModelToolbar {
  public readonly profile: number;

  public readonly countries: ModelHierarchyCountry[];

  public readonly region: number;

  public readonly stores: ModelHierarchyStore[];

  public readonly departments: ModelProductHierarchy[];

  public readonly subDepartments: ModelProductHierarchy[];

  public readonly families: ModelProductHierarchy[];

  public readonly isWorldSelected: boolean;

  public readonly isFavoriteCountriesSelected: boolean;

  public readonly isFavoriteStoresSelected: boolean;

  public readonly isFavoriteDepartmentsSelected: boolean;

  public readonly isFavoriteSubDepartmentsSelected: boolean;

  public readonly isFavoriteFamiliesSelected: boolean;

  public readonly limitResults: number;

  constructor({
    profile = null,
    countries = [],
    region = null,
    stores = [],
    departments = [],
    subDepartments = [],
    families = [],
    isWorldSelected = false,
    isFavoriteCountriesSelected = false,
    isFavoriteStoresSelected = false,
    isFavoriteDepartmentsSelected = false,
    isFavoriteSubDepartmentsSelected = false,
    isFavoriteFamiliesSelected = false,
    limitResults = SLIDER_DEFAULT_VALUE,
  }: {
    profile?: number;
    countries?: ModelHierarchyCountry[];
    region?: number;
    stores?: ModelHierarchyStore[];
    departments?: ModelProductHierarchy[];
    subDepartments?: ModelProductHierarchy[];
    families?: ModelProductHierarchy[];
    isWorldSelected?: boolean;
    isFavoriteCountriesSelected?: boolean;
    isFavoriteStoresSelected?: boolean;
    isFavoriteDepartmentsSelected?: boolean;
    isFavoriteSubDepartmentsSelected?: boolean;
    isFavoriteFamiliesSelected?: boolean;
    limitResults?: number;
  }) {
    this.profile = profile;
    this.countries = !isEmpty(countries)
      ? countries
      : [DEFAULT_SELECTOR_HIERARCHY_COUNTRY];
    this.region = region;
    this.stores = stores;
    this.departments = departments;
    this.subDepartments = subDepartments;
    this.families = families;
    this.isWorldSelected = isWorldSelected;
    this.isFavoriteCountriesSelected = isFavoriteCountriesSelected;
    this.isFavoriteStoresSelected = isFavoriteStoresSelected;
    this.isFavoriteDepartmentsSelected = isFavoriteDepartmentsSelected;
    this.isFavoriteSubDepartmentsSelected = isFavoriteSubDepartmentsSelected;
    this.isFavoriteFamiliesSelected = isFavoriteFamiliesSelected;
    this.limitResults = limitResults;
  }

  /**
   * Permet de clôner l'instance du model actuel, en modifiant certaines propriétés tout en gardant les autres.
   * @param profile
   * @param countries
   * @param stores
   * @param departments
   * @param subDepartments
   * @param families
   * @param isWorldSelected
   * @param isFavoriteCountriesSelected
   * @param isFavoriteStoresSelected
   * @param isFavoriteDepartmentsSelected
   * @param isFavoriteSubDepartmentsSelected
   * @param isFavoriteFamiliesSelected
   * @param limitResults
   * @param region
   */
  clone({
    profile = null,
    countries = null,
    region = null,
    stores = null,
    departments = null,
    subDepartments = null,
    families = null,
    isWorldSelected = null,
    isFavoriteCountriesSelected = null,
    isFavoriteStoresSelected = null,
    isFavoriteDepartmentsSelected = null,
    isFavoriteSubDepartmentsSelected = null,
    isFavoriteFamiliesSelected = null,
    limitResults = null,
  }: {
    profile?: OptionnalDropValue<number>;
    countries?: OptionnalDropValue<ModelHierarchyCountry[]>;
    region?: OptionnalDropValue<number>;
    stores?: OptionnalDropValue<ModelHierarchyStore[]>;
    departments?: OptionnalDropValue<ModelProductHierarchy[]>;
    subDepartments?: OptionnalDropValue<ModelProductHierarchy[]>;
    families?: OptionnalDropValue<ModelProductHierarchy[]>;
    isWorldSelected?: OptionnalDropValue<boolean>;
    isFavoriteCountriesSelected?: OptionnalDropValue<boolean>;
    isFavoriteStoresSelected?: OptionnalDropValue<boolean>;
    isFavoriteDepartmentsSelected?: OptionnalDropValue<boolean>;
    isFavoriteSubDepartmentsSelected?: OptionnalDropValue<boolean>;
    isFavoriteFamiliesSelected?: OptionnalDropValue<boolean>;
    limitResults?: OptionnalDropValue<number>;
  } = {}): ModelToolbar {
    const computeProfile = getValueOrDefaultOrDrop(profile, this.profile);
    const computeCountry = getValueOrDefaultOrDrop(countries, this.countries);
    const computeStore = getValueOrDefaultOrDrop(stores, this.stores);
    const computeDepartment = getValueOrDefaultOrEmptyArray(
      departments,
      this.departments,
    );
    const computeSubDepartment = getValueOrDefaultOrEmptyArray(
      subDepartments,
      this.subDepartments,
    );
    const computeFamily = getValueOrDefaultOrEmptyArray(
      families,
      this.families,
    );
    const computeIsWorldSelected = getValueOrDefaultOrDrop(
      isWorldSelected,
      this.isWorldSelected,
    );
    const computeIsFavoriteCountriesSelected = getValueOrDefaultOrDrop(
      isFavoriteCountriesSelected,
      this.isFavoriteCountriesSelected,
    );
    const computeIsFavoriteStoresSelected = getValueOrDefaultOrDrop(
      isFavoriteStoresSelected,
      this.isFavoriteStoresSelected,
    );
    const computeIsFavoriteDepartmentsSelected = getValueOrDefaultOrDrop(
      isFavoriteDepartmentsSelected,
      this.isFavoriteDepartmentsSelected,
    );
    const computeIsFavoriteSubDepartmentsSelected = getValueOrDefaultOrDrop(
      isFavoriteSubDepartmentsSelected,
      this.isFavoriteSubDepartmentsSelected,
    );
    const computeIsFavoriteFamiliesSelected = getValueOrDefaultOrDrop(
      isFavoriteFamiliesSelected,
      this.isFavoriteFamiliesSelected,
    );
    const computeLimitResults = getValueOrDefaultOrDrop(
      limitResults,
      this.limitResults,
    );
    const computeRegion = getValueOrDefaultOrDrop(region, this.region);

    return new ModelToolbar({
      profile: computeProfile,
      countries: computeCountry,
      region: computeRegion,
      stores: computeStore,
      departments: computeDepartment,
      subDepartments: computeSubDepartment,
      families: computeFamily,
      isWorldSelected: computeIsWorldSelected,
      isFavoriteCountriesSelected: computeIsFavoriteCountriesSelected,
      isFavoriteStoresSelected: computeIsFavoriteStoresSelected,
      isFavoriteDepartmentsSelected: computeIsFavoriteDepartmentsSelected,
      isFavoriteSubDepartmentsSelected: computeIsFavoriteSubDepartmentsSelected,
      isFavoriteFamiliesSelected: computeIsFavoriteFamiliesSelected,
      limitResults: computeLimitResults,
    });
  }

  /**
   * Permet de savoir si la sélection a évoluée par rapport a la version en snapshot
   * @param snapshot
   */
  isOutdated(snapshot: ModelToolbar): boolean {
    if (!snapshot) {
      return true;
    }

    if (!isEqual(this.profile, snapshot.profile)) {
      return true;
    }

    if (
      !isEqual(this.isWorldSelected, snapshot.isWorldSelected) ||
      !isEqual(this.countries, snapshot.countries) ||
      !isEqual(this.stores, snapshot.stores) ||
      !isEqual(
        this.isFavoriteCountriesSelected,
        snapshot.isFavoriteCountriesSelected,
      ) ||
      !isEqual(this.isFavoriteStoresSelected, snapshot.isFavoriteStoresSelected)
    ) {
      return true;
    }

    return (
      !isEqual(this.departments, snapshot.departments) ||
      !isEqual(this.subDepartments, snapshot.subDepartments) ||
      !isEqual(this.families, snapshot.families) ||
      !isEqual(
        this.isFavoriteDepartmentsSelected,
        snapshot.isFavoriteDepartmentsSelected,
      ) ||
      !isEqual(
        this.isFavoriteSubDepartmentsSelected,
        snapshot.isFavoriteSubDepartmentsSelected,
      ) ||
      !isEqual(
        this.isFavoriteFamiliesSelected,
        snapshot.isFavoriteFamiliesSelected,
      )
    );
  }

  /**
   * Permet d'initialiser la toolbar en fonction de l'URL et ce qui se trouve dans le localStorage
   * Nous donnons toujours la priorité à l'URL pour initialiser la toolbar
   * @param urlParsed
   * @param localStorage
   * @param profiles
   * @param countries
   * @param stores
   * @param departments
   * @param subDepartments
   * @param families
   */
  public static initialize({
    urlParsed,
    localStorage,
    profiles,
    countries,
    stores,
    departments,
    subDepartments,
    families,
  }: {
    urlParsed: URLSearchParams;
    localStorage: ModelToolbarLocalStorage;
    profiles: IProfiles;
    countries: ModelHierarchyCountry[];
    stores: ModelHierarchyStore[];
    departments: ModelProductHierarchy[];
    subDepartments: ModelProductHierarchy[];
    families: ModelProductHierarchy[];
  }): ModelToolbar {
    const profile = this.initializeProfile(profiles);

    const {
      countriesInitialized,
      isFavoriteCountriesSelected,
      isWorldSelected,
    } = this.initializeCountry(urlParsed, localStorage, countries);
    const { storesInitialized, isFavoriteStoresSelected } =
      this.initializeStore(urlParsed, localStorage, stores);
    const { departmentsInitialized, isFavoriteDepartmentsSelected } =
      this.initializeDepartment(urlParsed, localStorage, departments);
    const { subDepartmentsInitialized, isFavoriteSubDepartmentsSelected } =
      this.initializeSubDepartment(urlParsed, localStorage, subDepartments);
    const { familiesInitialized, isFavoriteFamiliesSelected } =
      this.initializeFamily(urlParsed, localStorage, families);
    const { region } = this.initializeRegion(urlParsed);
    const { limitResults } = this.initializeLimitResults(localStorage);

    return new ModelToolbar({
      profile: profile,
      countries: countriesInitialized,
      region: region,
      stores: storesInitialized,
      departments: departmentsInitialized,
      subDepartments: subDepartmentsInitialized,
      families: familiesInitialized,
      isWorldSelected: isWorldSelected,
      isFavoriteCountriesSelected: isFavoriteCountriesSelected,
      isFavoriteStoresSelected: isFavoriteStoresSelected,
      isFavoriteDepartmentsSelected: isFavoriteDepartmentsSelected,
      isFavoriteSubDepartmentsSelected: isFavoriteSubDepartmentsSelected,
      isFavoriteFamiliesSelected: isFavoriteFamiliesSelected,
      limitResults: limitResults,
    });
  }

  /**
   * Permet d'initialiser la valeur par défaut du pays en fonction de ce qui se trouve dans l'url & le localStorage
   * @param urlParsed
   * @param localStorage
   * @param countries
   * @private
   */
  private static initializeCountry(
    urlParsed: URLSearchParams,
    localStorage: ModelToolbarLocalStorage,
    countries: ModelHierarchyCountry[],
  ) {
    const foundCountryIds = urlParsed.get('countries')?.split(',') ||
      (!isEmpty(localStorage?.countries) && localStorage.countries) || [
        DEFAULT_SELECTOR_CODE_COUNTRY,
      ];

    const countryHierarchy = !isEmpty(foundCountryIds)
      ? map(foundCountryIds, (foundCountryId) => {
          return find(countries, (country) =>
            isEqual(country.id, foundCountryId),
          );
        })
      : [];

    return {
      countriesInitialized: compact(countryHierarchy),
      isFavoriteCountriesSelected: false,
      isWorldSelected: foundCountryIds.includes(WORLD_HIERARCHY_ID),
    };
  }

  /**
   * Permet d'initialiser la valeur par défaut de la region en fonction de ce qui se trouve dans l'url & le localStorage
   * @param urlParsed
   // * @param localStorage
   * @private
   */
  private static initializeRegion(urlParsed: URLSearchParams) {
    const foundRegionId = urlParsed.get('regions');

    return {
      region: toInteger(foundRegionId),
    };
  }

  private static initializeLimitResults(
    localStorage: ModelToolbarLocalStorage,
  ) {
    return {
      limitResults: localStorage?.limitResults
        ? localStorage.limitResults
        : SLIDER_DEFAULT_VALUE,
    };
  }

  /**
   * Permet d'initialiser la valeur par défaut du magasin en fonction de ce qui se trouve dans l'url & le localStorage
   * @param urlParsed
   * @param localStorage
   * @param stores
   * @private
   */
  private static initializeStore(
    urlParsed: URLSearchParams,
    localStorage: ModelToolbarLocalStorage,
    stores: ModelHierarchyStore[],
  ) {
    const foundStoresIdsUnique =
      urlParsed?.get('stores')?.split(',') || localStorage?.stores;

    const storeHierarchy = !isEmpty(foundStoresIdsUnique)
      ? map(foundStoresIdsUnique, (foundStoreIdUnique) => {
          if (typeof foundStoreIdUnique !== 'number') {
            const elementSplitted = foundStoreIdUnique?.split('-');
            return (
              elementSplitted &&
              find(
                stores,
                (store) =>
                  isEqual(store.type.value, toInteger(elementSplitted[0])) &&
                  isEqual(store.id, toInteger(elementSplitted[1])),
              )
            );
          }
          return null;
        })
      : [];

    return {
      storesInitialized: compact(storeHierarchy),
      isFavoriteStoresSelected: false,
    };
  }

  /**
   * Permet d'initialiser la valeur par défaut du sport en fonction de ce qui se trouve dans l'url & le localStorage
   * @param urlParsed
   * @param localStorage
   * @param departments
   * @private
   */
  private static initializeDepartment(
    urlParsed: URLSearchParams,
    localStorage: ModelToolbarLocalStorage,
    departments: ModelProductHierarchy[],
  ) {
    const foundDepartmentsIdsFromUrl = urlParsed.get('departments')?.split(',');
    const foundDepartmentsIdsFromLocalStorage = localStorage?.departments;

    const foundDepartmentsIds =
      foundDepartmentsIdsFromUrl ||
      (!urlParsed.get('subDepartments') &&
        !urlParsed.get('families') &&
        foundDepartmentsIdsFromLocalStorage);

    const foundDepartmentsHierarchies = !isEmpty(foundDepartmentsIds)
      ? map(foundDepartmentsIds, (foundDepartmentId) => {
          return find(departments, (department) =>
            isEqual(department.id, toInteger(foundDepartmentId)),
          );
        })
      : [];

    return {
      departmentsInitialized: compact(foundDepartmentsHierarchies),
      isFavoriteDepartmentsSelected: false,
    };
  }

  /**
   * Permet d'initialiser la valeur par défaut du sous-sport en fonction de ce qui se trouve dans l'url & le localStorage
   * @param urlParsed
   * @param localStorage
   * @param subDepartments
   * @private
   */
  private static initializeSubDepartment(
    urlParsed: URLSearchParams,
    localStorage: ModelToolbarLocalStorage,
    subDepartments: ModelProductHierarchy[],
  ) {
    const foundSubDepartmentsIdsFromUrl = urlParsed
      .get('subDepartments')
      ?.split(',');
    const foundSubDepartmentsIdsFromLocalStorage = localStorage?.subDepartments;
    const foundSubDepartmentsIds =
      foundSubDepartmentsIdsFromUrl ||
      (!urlParsed.get('departments') &&
        !urlParsed.get('families') &&
        foundSubDepartmentsIdsFromLocalStorage);

    const foundSubDepartmentsHierarchies = !isEmpty(foundSubDepartmentsIds)
      ? map(foundSubDepartmentsIds, (foundSubDepartmentId) => {
          return find(subDepartments, (subDepartment) =>
            isEqual(subDepartment.id, toInteger(foundSubDepartmentId)),
          );
        })
      : [];

    return {
      subDepartmentsInitialized: compact(foundSubDepartmentsHierarchies),
      isFavoriteSubDepartmentsSelected: false,
    };
  }

  /**
   * Permet d'initialiser la valeur par défaut de la famille en fonction de ce qui se trouve dans l'url & le localStorage
   * @param urlParsed
   * @param localStorage
   * @param families
   * @private
   */
  private static initializeFamily(
    urlParsed: URLSearchParams,
    localStorage: ModelToolbarLocalStorage,
    families: ModelProductHierarchy[],
  ) {
    const foundFamiliesIdsFromUrl = urlParsed.get('families')?.split(',');
    const foundFamiliesIdsFromLocalStorage = localStorage?.families;
    const foundFamiliesIds =
      foundFamiliesIdsFromUrl ||
      (!urlParsed.get('departments') &&
        !urlParsed.get('subDepartments') &&
        foundFamiliesIdsFromLocalStorage);

    const foundFamiliesHierarchies = !isEmpty(foundFamiliesIds)
      ? map(foundFamiliesIds, (foundFamilyId) => {
          return find(families, (family) =>
            isEqual(family.id, toInteger(foundFamilyId)),
          );
        })
      : [];

    return {
      familiesInitialized: compact(foundFamiliesHierarchies),
      isFavoriteFamiliesSelected: false,
    };
  }

  /**
   * Permet d'initialiser le profile
   * ⚠️ Le profile ne transit pas dans l'url et n'est pas sauvegardé dans le local storage pour le moment
   * @param profiles
   * @private
   */
  private static initializeProfile(profiles: IProfiles): number {
    const foundDefaultProfile = find(profiles, (profile) => profile.by_default);
    return foundDefaultProfile?.id;
  }

  /**
   * Permet de générer les query params associé à la sélection
   */
  public queryParams(pageParams: IPageParams): string[] {
    const params = [];
    if (
      !isEmpty(this.countries) &&
      this.isWorldSelected &&
      pageParams.withWorld
    ) {
      params.push(`countries=${WORLD_HIERARCHY_ID}`);
    }

    if (
      !isEmpty(this.countries) &&
      pageParams?.withCountries &&
      !params.includes(`countries=${WORLD_HIERARCHY_ID}`)
    ) {
      params.push(
        `countries=${map(this.countries, (country) => country.id).join(',')}`,
      );
    }

    if (this.region && pageParams?.withRegions) {
      params.push(`regions=${this.region}`);
    }

    if (!isEmpty(this.stores) && pageParams?.withStores) {
      params.push(
        `stores=${map(this.stores, (store) => store.idUnique).join(',')}`,
      );
    }

    if (!isEmpty(this.departments) && pageParams?.withDepartments) {
      params.push(
        `departments=${map(
          this.departments,
          (department) => department?.id,
        ).join(',')}`,
      );
    }

    if (!isEmpty(this.subDepartments) && pageParams?.withSubDepartments) {
      params.push(
        `subDepartments=${map(
          this.subDepartments,
          (subDepartment) => subDepartment.id,
        ).join(',')}`,
      );
    }

    if (!isEmpty(this.families) && pageParams?.withFamilies) {
      params.push(
        `families=${map(this.families, (family) => family.id).join(',')}`,
      );
    }

    return params;
  }

  /**
   * Permet de récupérer le niveau de descente hiérarchique produit en fonction de ce qui est sélectionné
   */
  public get typeTreeLevel(): ModelTypeTreeLevel {
    if (!isEmpty(this.families)) {
      return TypeTreeLevel.MODEL;
    }
    if (!isEmpty(this.subDepartments)) {
      return TypeTreeLevel.FAMILY;
    }
    if (!isEmpty(this.departments)) {
      return TypeTreeLevel.SUB_DEPARTMENT;
    }
    return TypeTreeLevel.DEPARTMENT;
  }

  /**
   * Permet de récupérer le niveau de la hiérarchie produit en fonction de ce qui est sélectionné
   */
  public get trueTypeTreeLevel(): ModelTypeTreeLevel {
    if (!isEmpty(this.families)) {
      return TypeTreeLevel.FAMILY;
    }
    if (!isEmpty(this.subDepartments)) {
      return TypeTreeLevel.SUB_DEPARTMENT;
    }
    if (!isEmpty(this.departments)) {
      return TypeTreeLevel.DEPARTMENT;
    }
    return null;
  }

  /**
   * Permet de descendre dans la hierarchie en fonction de ce qui est sélectionné au niveau produit
   * @param hierarchy
   * @param departments
   * @param subDepartments
   */
  public downInProductHierarchy(
    hierarchy: ModelProductHierarchy,
    departments: ModelProductHierarchy[],
    subDepartments: ModelProductHierarchy[],
  ): ModelToolbar {
    if (isEqual(hierarchy.level, TypeTreeLevel.DEPARTMENT)) {
      return this.clone({
        departments: [hierarchy],
        subDepartments: new DropValue(),
        families: new DropValue(),
        isFavoriteDepartmentsSelected: false,
        isFavoriteSubDepartmentsSelected: false,
        isFavoriteFamiliesSelected: false,
      });
    }
    if (isEqual(hierarchy.level, TypeTreeLevel.SUB_DEPARTMENT)) {
      const associatedDepartmentHierarchy = hierarchy
        ? ModelProductHierarchy.findHierarchy(
            departments,
            hierarchy.parent.id,
            TypeTreeLevel.DEPARTMENT,
          )
        : null;

      return this.clone({
        departments: [associatedDepartmentHierarchy],
        subDepartments: [hierarchy],
        families: new DropValue(),
        isFavoriteDepartmentsSelected: false,
        isFavoriteSubDepartmentsSelected: false,
        isFavoriteFamiliesSelected: false,
      });
    }
    if (isEqual(hierarchy.level, TypeTreeLevel.FAMILY)) {
      const associatedSubDepartmentHierarchy = hierarchy
        ? ModelProductHierarchy.findHierarchy(
            subDepartments,
            hierarchy.parent.id,
            TypeTreeLevel.SUB_DEPARTMENT,
          )
        : null;
      const associatedDepartmentHierarchy = associatedSubDepartmentHierarchy
        ? ModelProductHierarchy.findHierarchy(
            departments,
            associatedSubDepartmentHierarchy.parent.id,
            TypeTreeLevel.DEPARTMENT,
          )
        : null;

      return this.clone({
        departments: [associatedDepartmentHierarchy],
        subDepartments: [associatedSubDepartmentHierarchy],
        families: [hierarchy],
        isFavoriteDepartmentsSelected: false,
        isFavoriteSubDepartmentsSelected: false,
        isFavoriteFamiliesSelected: false,
      });
    }
    return null;
  }

  /**
   * Permet de remonter dans la hiérarchie en fonction de ce qui est sélectionner au niveau produit
   */
  public upInProductHierarchy(): ModelToolbar {
    if (
      !this.trueTypeTreeLevel ||
      isEmpty(this[this.trueTypeTreeLevel.keyHierarchies])
    ) {
      return null;
    }

    const departments = isEqual(
      this.trueTypeTreeLevel,
      TypeTreeLevel.DEPARTMENT,
    )
      ? new DropValue()
      : null;
    const subDepartments = isEqual(
      this.trueTypeTreeLevel,
      TypeTreeLevel.SUB_DEPARTMENT,
    )
      ? new DropValue()
      : null;
    const families = isEqual(this.trueTypeTreeLevel, TypeTreeLevel.FAMILY)
      ? new DropValue()
      : null;

    return this.clone({
      departments,
      subDepartments,
      families,
    });
  }

  /**
   * Permet de savoir s'il est possible de remonter dans la hiérarchie en fonction de la sélection
   */
  public get canUpInProductHierarchy(): boolean {
    if (!this.trueTypeTreeLevel) {
      return false;
    }

    return !(
      this.trueTypeTreeLevel.parent &&
      isEmpty(this[this.trueTypeTreeLevel.parent.keyHierarchies])
    );
  }

  public get newSelectorProductParent(): ModelToolbar {
    if (
      this.isFavoriteDepartmentsSelected ||
      this.isFavoriteSubDepartmentsSelected ||
      this.isFavoriteFamiliesSelected
    ) {
      return this.clone({
        isFavoriteDepartmentsSelected: false,
        isFavoriteSubDepartmentsSelected: false,
        isFavoriteFamiliesSelected: false,
      });
    }
    if (
      !isEmpty(this.departments) &&
      !isEmpty(this.subDepartments) &&
      !isEmpty(this.families)
    ) {
      return this.clone({
        families: new DropValue(),
        isFavoriteDepartmentsSelected: false,
        isFavoriteSubDepartmentsSelected: false,
        isFavoriteFamiliesSelected: false,
      });
    }
    if (
      !isEmpty(this.departments) &&
      !isEmpty(this.subDepartments) &&
      isEmpty(this.families)
    ) {
      return this.clone({
        subDepartments: new DropValue(),
        families: new DropValue(),
        isFavoriteDepartmentsSelected: false,
        isFavoriteSubDepartmentsSelected: false,
        isFavoriteFamiliesSelected: false,
      });
    }
    if (
      !isEmpty(this.departments) &&
      isEmpty(this.subDepartments) &&
      isEmpty(this.families)
    ) {
      return this.clone({
        departments: new DropValue(),
        subDepartments: new DropValue(),
        families: new DropValue(),
        isFavoriteDepartmentsSelected: false,
        isFavoriteSubDepartmentsSelected: false,
        isFavoriteFamiliesSelected: false,
      });
    }

    return null;
  }

  public get newSelectorParent(): ModelToolbar {
    if (this.isFavoriteStoresSelected || this.isFavoriteCountriesSelected) {
      return this.clone({
        isWorldSelected: false,
        isFavoriteCountriesSelected: false,
        isFavoriteStoresSelected: false,
      });
    }
    if (!isEmpty(this.stores)) {
      return this.clone({
        countries: this.countries,
        stores: new DropValue(),
      });
    }

    return this.clone({
      isWorldSelected: true,
    });
  }
}

export default ModelToolbar;
