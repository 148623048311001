import { useEffect } from 'react';
import { useAuth } from 'oidc-react';
import { useDispatch, useSelector } from 'react-redux';
import Initialization from './Initialization';
import { IStateApp } from '../common/reducers';
import AuthenticationError from './router/errors/AuthenticationError';
import { setUser } from '../common/reducers/authentication';

interface AuthenticatedAppProps {
  readonly error?: Error;
}

export default function AuthenticatedApp({ error }: AuthenticatedAppProps) {
  const auth = useAuth();
  const dispatch = useDispatch();
  const { user } = useSelector((state: IStateApp) => state.authentication);

  useEffect(() => {
    dispatch(
      setUser({
        user: auth.userData,
      }),
    );
  }, [dispatch, auth]);

  return (
    <>
      {error && <AuthenticationError error={error} />}
      {user && <Initialization />}
    </>
  );
}

AuthenticatedApp.defaultProps = { error: null };
