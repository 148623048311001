import { filter } from 'lodash';

import TypeTableColumnEnum from 'common/models/enums/types/TypeTableColumnEnum';
import TypeTableViewOptionEnum from 'common/models/enums/types/TypeTableViewOptionEnum';
import ModelTableViewOption from 'common/models/enums/models/ModelTableViewOption';
import ModelTableColumn from 'common/models/enums/models/ModelTableColumn';
import ModelTableSortMethod from 'common/models/enums/models/ModelTableSortMethod';
import TypeTableSortMethodEnum from 'common/models/enums/types/TypeTableSortMethodEnum';
import { ModelKpi } from 'common/models/enums/models/kpi-enum';
import ModelTableAggregation from 'common/models/enums/models/ModelTableAggregation';
import TypeTableAggregationEnum from 'common/models/enums/types/TypeTableAggregationEnum';

export interface IStateTable {
  viewOptions: ModelTableViewOption[];
  columns: ModelTableColumn[];
  sortBy: ModelTableSortMethod;
  aggregation: ModelTableAggregation;
  delta?: ModelKpi;
  paginate: boolean;
  showBorder: boolean;
  fixHeader: boolean;
}

const INITIAL_STATE_TABLE: IStateTable = {
  columns: filter(TypeTableColumnEnum.ALL, (column) => column.isDefault),
  viewOptions: filter(
    TypeTableViewOptionEnum.ALL,
    (viewOption) => viewOption.isDefault,
  ),
  sortBy: TypeTableSortMethodEnum.ALL.find(
    (sortMethod) => sortMethod.isDefault,
  ),
  aggregation: TypeTableAggregationEnum.ALL.find(
    (sortMethod) => sortMethod.isDefault,
  ),
  paginate: false,
  showBorder: false,
  fixHeader: false,
};

export default INITIAL_STATE_TABLE;
