import INITIAL_STATE from 'common/constants/initial-state-filters';
import { UpdateFiltersType } from 'common/constants/action-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const filtersSlice = createSlice({
  name: 'filters',
  initialState: INITIAL_STATE,
  reducers: {
    updateFilters: (_, action: PayloadAction<UpdateFiltersType>) => {
      return action.payload;
    },
  },
});

export const { updateFilters } = filtersSlice.actions;
export default filtersSlice.reducer;
