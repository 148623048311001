import { filter, get, isEmpty, isEqual, map } from 'lodash';

import ModelProductHierarchy from 'common/models/hierarchies/products/product-hierarchy';
import TypeTreeLevel from 'common/models/enums/types/type-tree-level';
import ModelTypeTreeLevel from 'common/models/enums/models/type-tree-level';

import ApiRequestUtils from 'common/api/api-request';
import DateUtils from 'common/utils/date';
import store from 'common/store';
import ModelToolbar from 'common/models/ModelToolbar';
import { findHierarchies } from 'common/reducers/global';
import { updateToolbar } from 'common/reducers/toolbar';
import { Dispatch } from 'redux';
import { ModelToolbarLocalStorage } from 'common/models/interfaces/toolbar';
import LocalStorageUtils from 'common/utils/local-storage';
import { USER_TOOLBAR } from 'common/constants/cookies-keys';
import { TIME_WEEK } from 'common/constants/time';
import i18next from 'i18next';

export const loadInitRequest = () => {
  const url = ApiRequestUtils.getBffUrl({
    pathUrl: 'init',
    version: 'v4',
    params: {
      from: DateUtils.getYesterday(),
    },
  });

  const { user } = store.getState().authentication;

  return ApiRequestUtils.request(url, user?.access_token, i18next.language, {
    isRetryableRequest: true,
    isCancellableRequest: false,
  });
};

export const loadLabelsRequest = (
  typeTreeLevel?: ModelTypeTreeLevel,
  toolbar?: ModelToolbar,
  modelsIds?: number[],
) => {
  const { user } = store.getState().authentication;

  const url = ApiRequestUtils.getBffUrl({
    pathUrl: typeTreeLevel?.urlLabel || 'labels/geographical',
    version: 'v4',
    params: {
      aggLevel: typeTreeLevel?.aggLevel,
      from: DateUtils.getYesterday(),
      family:
        isEqual(typeTreeLevel, TypeTreeLevel.MODEL) && toolbar?.families
          ? map(toolbar.families, (family) => family.id)
          : undefined,
      models:
        isEqual(typeTreeLevel, TypeTreeLevel.MODEL) && !isEmpty(modelsIds)
          ? modelsIds
          : undefined,
    },
  });

  return ApiRequestUtils.request(url, user?.access_token, i18next.language, {
    isRetryableRequest: true,
    isCancellableRequest: false,
  });
};

export const updateModelsLabels = (models: number[]) => (dispatch) => {
  const hierarchies = store.getState().global.models;

  const modelsToLoad = models.filter(
    (model) =>
      !hierarchies.find((productHierarchy) => productHierarchy.id === model),
  );

  if (isEmpty(modelsToLoad)) {
    return null;
  }

  return loadLabelsRequest(TypeTreeLevel.MODEL, null, modelsToLoad).then(
    (response) => {
      const productHierarchiesUpdated: ModelProductHierarchy[] = filter(
        map(get(response, 'data'), (r) =>
          ModelProductHierarchy.fromApiModelProductHierarchy(r),
        ),
        (productHierarchy) =>
          productHierarchy.label && productHierarchy.label.trim() !== '',
      );

      dispatch(
        findHierarchies({
          models: [...hierarchies, ...productHierarchiesUpdated],
        }),
      );
    },
  );
};

/**
 * Permet de persister la selection en cours dans le local storage lorsque la sauvegarde est demandée
 *  @param toolbar
 */
function persistToolbar(toolbar: ModelToolbar): void {
  const modelToolbarToJson: ModelToolbarLocalStorage = {
    countries: map(toolbar.countries, (country) => country?.id),
    stores: map(toolbar.stores, (store) => store?.idUnique),
    departments: map(toolbar.departments, (department) => department?.id),
    subDepartments: map(
      toolbar.subDepartments,
      (subDepartment) => subDepartment.id,
    ),
    families: map(toolbar.families, (family) => family.id),
    region: toolbar.region,
    limitResults: toolbar.limitResults,
  };

  return LocalStorageUtils.setItem(
    USER_TOOLBAR,
    JSON.stringify(modelToolbarToJson),
    TIME_WEEK,
  );
}

/**
 * Permet d'envoyer les changements dans redux et de persister ces dernier dans le local storage.
 * @param toolbar
 * @param dispatch
 */
export function updateAndPersistToolbar(
  toolbar: ModelToolbar,
  dispatch: Dispatch,
): void {
  dispatch(updateToolbar(toolbar));

  persistToolbar(toolbar);
}
