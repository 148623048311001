import { isEmpty, map } from 'lodash';
import TypeCurrencyFilter from 'common/models/enums/types/currency-filter';
import IModelHeader from 'common/models/interfaces/header';
import ModelToolbar from 'common/models/ModelToolbar';
import { Dispatch } from 'redux';
import { ModelFiltersLocalStorage } from 'common/models/interfaces/filters';
import LocalStorageUtils from 'common/utils/local-storage';
import { USER_FILTERS } from 'common/constants/cookies-keys';
import { TIME_WEEK } from 'common/constants/time';
import ModelFilters from 'common/models/ModelFilters';
import { updateFilters } from 'common/reducers/filters';

export const getDefaultCurrencyBySelection = (
  toolbar: ModelToolbar,
  header: IModelHeader,
) => {
  let currency = TypeCurrencyFilter.LOCAL;

  if (
    (isEmpty(toolbar.countries) ||
      (!isEmpty(toolbar.countries) && toolbar.countries.length > 1)) &&
    header.propsPopupSelector.withWorld
  ) {
    currency = TypeCurrencyFilter.EURO;
  }

  if (
    !isEmpty(toolbar.countries) &&
    toolbar.countries.length === 1 &&
    header.propsPopupSelector.withCountries
  ) {
    currency = TypeCurrencyFilter.LOCAL;
  }

  return currency;
};

function persistFilters(filters: ModelFilters): void {
  const modelFiltersToJson: ModelFiltersLocalStorage = {
    dateRange: filters?.dateRange?.localStorageValue,
    dateRangeComparable: null,
    dateTypeComparable: filters?.dateTypeComparable?.value,
    kpis: filters.kpis && map(filters.kpis, (filter) => filter.value),
    salesSupport:
      filters.salesSupport && map(filters.salesSupport, (filter) => filter.id),
    increase: filters?.increase.value,
    currency: filters?.currency.value,
    productsType:
      filters.productsType &&
      map(filters.productsType, (productType) => productType.value),
    returnIncluded: filters.returnIncluded,
  };

  return LocalStorageUtils.setItem(
    USER_FILTERS,
    JSON.stringify(modelFiltersToJson),
    TIME_WEEK,
  );
}

export function updateFiltersAndPersist(
  filters: ModelFilters,
  dispatch: Dispatch,
): void {
  dispatch(updateFilters(filters));
  persistFilters(filters);
}
