import { createRoot } from 'react-dom/client';
import Numeral from 'numeral';

import 'sass/index.scss';

import App from 'core/App';

Numeral.localeData().delimiters.thousands = ' ';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App />);
