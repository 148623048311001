import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import INITIAL_STATE from 'common/constants/initial-state-table';
import { UpdateTableType } from 'common/constants/action-types';

const tableSlice = createSlice({
  name: 'table',
  initialState: INITIAL_STATE,
  reducers: {
    updateTable: (state, action: PayloadAction<UpdateTableType>) => {
      Object.assign(state, action.payload.table);
    },
  },
});

export const { updateTable } = tableSlice.actions;
export default tableSlice.reducer;
