import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import INITIAL_STATE from 'common/constants/initial-state-toolbar';
import { UpdateToolbarType } from 'common/constants/action-types';

const toolbarSlice = createSlice({
  name: 'toolbar',
  initialState: INITIAL_STATE,
  reducers: {
    updateToolbar: (_, action: PayloadAction<UpdateToolbarType>) => {
      return action.payload;
    },
  },
});

export const { updateToolbar } = toolbarSlice.actions;
export default toolbarSlice.reducer;
