import i18next from 'i18next';
const i18n = i18next;
import { VpRulerIcon } from '@vtmn-play/icons/react';

import ModelFilters from 'common/models/ModelFilters';
import { IModelTable } from 'common/models/interfaces/table';
import ModelToolbar from 'common/models/ModelToolbar';
import { IPageParams } from 'common/models/interfaces/popup-selector';
import { generateParams } from 'common/utils/navigation';

const getLinkBySelectors = (
  filters: ModelFilters,
  table: IModelTable,
  toolbar: ModelToolbar,
  pageParams: IPageParams,
) => {
  let link = '/caml';

  link += generateParams(filters, table, toolbar, pageParams);

  return link;
};

export default {
  title: () => i18n.t(`navigation.label.caml`),
  id: 'caml',
  icon: (
    <VpRulerIcon
      style={{
        fontSize: '4rem',
        marginLeft: '-6px',
        height: '28px',
        width: '28px',
      }}
    />
  ),
  order: 5,
  link: getLinkBySelectors,
  paths: ['/caml'],
  subLinks: null,
  isNotAccessibleForFranchised: false,
};
