import dayjs, { Dayjs } from 'dayjs';
import { isEmpty, isEqual } from 'lodash';
import NumberUtils from 'common/utils/number';
import { MINUTE_IN_HOUR } from 'common/constants/time';
import { LIMIT_END_DATE } from 'common/constants/constants';
import ModelDateTypeComparable from 'common/models/enums/models/date-type-comparable';
import TypeDateComparableType from 'common/models/enums/types/date-type-comparable';
import { DATE_RANGE_FILTER_SECTION_NAME } from 'common/constants/filter-sections';
import TypeDateRange from 'common/models/enums/types/date-range';
import ModelDateRange from 'common/models/enums/models/date-range';

export default class DateUtils {
  public static getPeriodByType(
    dateRange: ModelDateRange,
    type: ModelDateTypeComparable,
    nYear = 1,
  ): ModelDateRange {
    if (!dateRange) {
      return null;
    }

    if (!dateRange.from || !dateRange.to) {
      return dateRange;
    }

    if (isEqual(type, TypeDateComparableType.CALENDAR_DATE)) {
      return TypeDateRange.getCustomDate(
        this.getDateComparable(dateRange.from, nYear),
        this.getDateComparable(dateRange.to, nYear),
      );
    }

    if (isEqual(type, TypeDateComparableType.DAY_OF_WEEK)) {
      return TypeDateRange.getCustomDate(
        this.getDayComparable(dateRange.from, nYear),
        this.getDayComparable(dateRange.to, nYear),
      );
    }

    return null;
  }

  public static getDateComparable(d: Dayjs, nYear = 1): Dayjs {
    return d.subtract(nYear, 'year');
  }

  public static getDayComparable(d: Dayjs, nYear = 1): Dayjs {
    return d.subtract(52 * nYear, 'week');
  }

  public static getGMTTimeZone(): string {
    const timezoneOffset = this.getFakeDateIfNotProduction().utcOffset();

    const sign = timezoneOffset * -1 < 0 ? '-' : '+';

    const hours = Math.floor(Math.abs(timezoneOffset) / MINUTE_IN_HOUR);
    const minutes = timezoneOffset % MINUTE_IN_HOUR;

    return `GMT${sign}${NumberUtils.leadingZeroDate(
      hours,
    )}${NumberUtils.leadingZeroDate(minutes)}`;
  }

  public static toStringDate(date: string): Dayjs {
    return dayjs(date, 'YYYYMMDD');
  }

  public static toDateString(date: Dayjs): string {
    return date.format('YYYYMMDD');
  }

  public static toHumanDateString(date: Dayjs): string {
    return date.format('D MMMM YYYY');
  }

  public static getYesterday(): Dayjs {
    return this.getToday().startOf('day').subtract(1, 'day');
  }

  public static getFirstDayOfCurrentMonthMinusOneYear(): Dayjs {
    return this.getToday().startOf('month').subtract(1, 'year');
  }

  public static getFromDateOrDefaultYesterday(from = DateUtils.getYesterday()) {
    return from;
  }

  public static getToday(): Dayjs {
    return this.getFakeDateIfNotProduction();
  }

  public static getDateLastSaturday(from = this.getYesterday()): Dayjs {
    return dayjs(from).startOf('week').subtract(1, 'day');
  }

  public static getDateLastSunday(from): Dayjs {
    return dayjs(from).startOf('week').subtract(1, 'week');
  }

  public static getDateBeginningWeek(): Dayjs {
    return this.getYesterday().startOf('week');
  }

  public static getDateMinusWeek(
    numberWeekBefore = 1,
    from = this.getYesterday(),
  ): Dayjs {
    return dayjs(from).subtract(numberWeekBefore, 'week');
  }

  public static getDateBeginningMonth(
    numberMonthBefore = 0,
    from = this.getYesterday(),
  ): Dayjs {
    return dayjs(from).subtract(numberMonthBefore, 'month').startOf('month');
  }

  public static getDateEndMonth(
    numberMonthBefore = 0,
    from = this.getYesterday(),
  ): Dayjs {
    return numberMonthBefore === 0
      ? dayjs(from).endOf('month')
      : dayjs(from)
          .subtract(numberMonthBefore, 'month')
          .endOf('month')
          .startOf('day');
  }

  public static getDateBeginningYear(from = this.getYesterday()): Dayjs {
    return dayjs(from).startOf('year');
  }

  public static get4sStart(from = this.getYesterday()): Dayjs {
    return dayjs(from).startOf('week').subtract(4, 'week');
  }

  public static cloneDateWithoutTime(date: Date): Date {
    return dayjs(date).startOf('day').toDate();
  }

  public static fromApiDateString(dateApiRequest: string): Dayjs {
    return dayjs(dateApiRequest);
  }

  public static getLastDayFromPreviousMonth(aDate: Dayjs): Dayjs {
    return dayjs(aDate).subtract(1, 'months').endOf('month').startOf('day');
  }

  public static isDisabledDate(
    date: Dayjs,
    from: Dayjs,
    to: Dayjs,
    disabled: Array<string>,
  ) {
    // si je suis sur la synthese je désactive la selection personnalisée
    if (disabled?.includes(`${DATE_RANGE_FILTER_SECTION_NAME}.customDate`)) {
      return true;
    }

    // si la date au dessus de la date courante
    // je désactive
    if (date && date >= DateUtils.getToday().startOf('day')) {
      return true;
    }

    // on prend la date du jour et on permet à l'utilisateur de remonté max à 2 ans en sélection en commencant au 1er janvier de l'année
    if (
      DateUtils.getToday()
        .subtract(2, 'years')
        .startOf('year')
        .isAfter(date, 'day')
    ) {
      return true;
    }

    // si j'ai une  date de début, pas de date de fin
    // je desactive au dessus de date de début + 366 jours
    if (from && !to && Math.abs(from.diff(date, 'days')) > LIMIT_END_DATE) {
      return true;
    }
    return !!(to && !from && Math.abs(to.diff(date, 'days')) > LIMIT_END_DATE);
  }

  public static isDisabledDateComparable(
    dateRange: Array<Dayjs>,
    dateRangeComparable: Array<Dayjs>,
    date: Dayjs,
  ): boolean {
    // si la date est au dessus du from de la période je désactive
    // => la période comparable doit être strictement inférieur à la période
    if (isEmpty(dateRange) || date.diff(dateRange[0]) >= 0) {
      return true;
    }

    // si j'ai une date de début, pas de date de fin
    // je desactive au dessus de date de début + 366 jours
    if (
      dateRangeComparable?.[1] &&
      !dateRangeComparable?.[0] &&
      Math.abs(dateRangeComparable?.[1]?.diff(date, 'days')) > LIMIT_END_DATE
    ) {
      return true;
    }
    return (
      dateRangeComparable?.[0] &&
      !dateRangeComparable?.[1] &&
      Math.abs(dateRangeComparable?.[0]?.diff(date, 'days')) > LIMIT_END_DATE
    );
  }

  public static readonly limitRangeStartWhenSelected = (
    start: Dayjs,
    end: Dayjs,
  ): ModelDateRange => {
    if (
      start &&
      end &&
      (Math.abs(start.diff(end, 'days')) > LIMIT_END_DATE ||
        end.isBefore(start))
    ) {
      return TypeDateRange.getCustomDate(start, null);
    }
    if (!start) {
      return TypeDateRange.getCustomDate(null, end);
    }

    return TypeDateRange.getCustomDate(start, end);
  };

  public static readonly limitRangeEndWhenSelected = (
    start: Dayjs,
    end: Dayjs,
  ): ModelDateRange => {
    if (
      end &&
      start &&
      (Math.abs(end.diff(start, 'days')) > LIMIT_END_DATE || start.isAfter(end))
    ) {
      return TypeDateRange.getCustomDate(null, end);
    }
    if (!end) {
      return TypeDateRange.getCustomDate(start, null);
    }

    return TypeDateRange.getCustomDate(start, end);
  };

  private static getFakeDateIfNotProduction(): Dayjs {
    return isEmpty(import.meta.env.VITE_FAKE_DATE)
      ? dayjs()
      : dayjs(import.meta.env.VITE_FAKE_DATE);
  }
}
