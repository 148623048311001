import { useTranslation } from 'react-i18next';

export const useI18n = () => {
  const { t, i18n } = useTranslation(); // Access `t` and `i18n` (i18next instance)

  return {
    t, // For translations
    locale: i18n.language, // For current locale
    changeLanguage: i18n.changeLanguage, // Method to change language
  };
};
