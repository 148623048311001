import DateUtils from 'common/utils/date';
import { find } from 'lodash';

import AbstractEnumType from 'common/models/enums/types/abstract-enum-type';
import ModelDateRange from 'common/models/enums/models/date-range';
import { Dayjs } from 'dayjs';
import TypeDateComparableType from 'common/models/enums/types/date-type-comparable';

export default class TypeDateRange extends AbstractEnumType {
  public static readonly TODAY = new ModelDateRange({
    value: 'today',
    label: 'application.filter.date-range.today',
    shortLabel: 'application.filter.date-range.today',
    from: (from = DateUtils.getToday()) => from,
    to: (from = DateUtils.getToday()) => from,
  });

  public static readonly YESTERDAY = new ModelDateRange({
    value: 'yesterday',
    label: 'application.filter.date-range.day-minus-one',
    shortLabel: 'application.filter.date-range.short.day-minus-one',
    from: (from = DateUtils.getYesterday()) => from,
    to: (from = DateUtils.getYesterday()) => from,
    dateTypeComparable: TypeDateComparableType.DAY_OF_WEEK,
    weight: 1,
  });

  public static readonly LAST_SATURDAY = new ModelDateRange({
    value: 'lastSaturday',
    label: 'application.filter.date-range.last-saturday',
    shortLabel: 'application.filter.date-range.short.last-saturday',
    from: (from = DateUtils.getToday()) => DateUtils.getDateLastSaturday(from),
    to: (from = DateUtils.getToday()) => DateUtils.getDateLastSaturday(from),
    dateTypeComparable: TypeDateComparableType.DAY_OF_WEEK,
  });

  public static readonly WEEK_TO_DATE = new ModelDateRange({
    value: 'weekToDate',
    label: 'application.filter.date-range.since-beginning-week',
    shortLabel: 'application.filter.date-range.short.since-beginning-week',
    from: () => DateUtils.getDateBeginningWeek(),
    to: (from) => DateUtils.getFromDateOrDefaultYesterday(from),
    dateTypeComparable: TypeDateComparableType.DAY_OF_WEEK,
    weight: 3,
  });

  public static readonly WEEK_ONE = new ModelDateRange({
    value: 'lastWeek',
    label: 'application.filter.date-range.week-minus-one',
    shortLabel: 'application.filter.date-range.short.week-minus-one',
    from: (from = DateUtils.getToday()) => DateUtils.getDateLastSunday(from),
    to: (from = DateUtils.getToday()) => DateUtils.getDateLastSaturday(from),
    dateTypeComparable: TypeDateComparableType.DAY_OF_WEEK,
    weight: 4,
  });

  public static readonly MONTH_TO_DATE = new ModelDateRange({
    value: 'monthToDate',
    label: 'application.filter.date-range.since-beginning-month',
    shortLabel: 'application.filter.date-range.short.since-beginning-month',
    from: (from = DateUtils.getYesterday()) =>
      DateUtils.getDateBeginningMonth(0, from),
    to: (from) => DateUtils.getFromDateOrDefaultYesterday(from),
    dateTypeComparable: TypeDateComparableType.CALENDAR_DATE,
    weight: 5,
  });

  public static readonly MONTH_ONE = new ModelDateRange({
    value: 'lastMonth',
    label: 'application.filter.date-range.month-minus-one',
    shortLabel: 'application.filter.date-range.short.month-minus-one',
    from: (from = DateUtils.getYesterday()) =>
      DateUtils.getDateBeginningMonth(1, from),
    to: (from = DateUtils.getYesterday()) => DateUtils.getDateEndMonth(1, from),
    dateTypeComparable: TypeDateComparableType.CALENDAR_DATE,
    weight: 6,
  });

  public static readonly FOUR_S = new ModelDateRange({
    value: '4s',
    label: 'application.filter.date-range.4s',
    shortLabel: 'application.filter.date-range.short.4s-short',
    from: (from = DateUtils.getYesterday()) => DateUtils.get4sStart(from),
    to: (from) => DateUtils.getDateLastSaturday(from),
    dateTypeComparable: TypeDateComparableType.DAY_OF_WEEK,
  });

  public static readonly YEAR_TO_DATE = new ModelDateRange({
    value: 'yearToDate',
    label: 'application.filter.date-range.since-beginning-year',
    shortLabel: 'application.filter.date-range.short.since-beginning-year',
    from: (from = DateUtils.getYesterday()) =>
      DateUtils.getDateBeginningYear(from),
    to: (from) => DateUtils.getFromDateOrDefaultYesterday(from),
    dateTypeComparable: TypeDateComparableType.CALENDAR_DATE,
    weight: 7,
  });

  public static readonly YEAR_SLIDING = new ModelDateRange({
    value: 'yearSliding',
    label: 'application.filter.date-range.year-sliding',
    shortLabel: 'application.filter.date-range.short.year-sliding',
    from: (from = DateUtils.getYesterday()) =>
      DateUtils.getDateMinusWeek(52, from),
    to: (from) => DateUtils.getFromDateOrDefaultYesterday(from),
    dateTypeComparable: TypeDateComparableType.CALENDAR_DATE,
    weight: 8,
  });

  public static readonly TWELVE_MONTHS_BEFORE = new ModelDateRange({
    value: 'twelveMonthsBefore',
    label: 'application.filter.date-range.twelve-months',
    shortLabel: 'application.filter.date-range.short.twelve-months',
    from: () => DateUtils.getFirstDayOfCurrentMonthMinusOneYear(),
    to: (from = DateUtils.getYesterday()) =>
      DateUtils.getLastDayFromPreviousMonth(from),
    dateTypeComparable: TypeDateComparableType.CALENDAR_DATE,
  });

  public static readonly ALL: ModelDateRange[] = this.all(
    TypeDateRange,
    ModelDateRange,
  );

  public static get CUSTOM_DATE(): ModelDateRange {
    return this.getCustomDate(DateUtils.getYesterday());
  }

  public static getCustomDate(from: Dayjs, to?: Dayjs): ModelDateRange {
    return new ModelDateRange({
      value: 'customDate',
      label: 'application.filter.date-range.custom-date',
      shortLabel: 'application.filter.date-range.short.custom-date',
      from: () => from,
      to: () => to,
      dateTypeComparable: TypeDateComparableType.CALENDAR_DATE,
      weight: null,
      getToLastYear: null,
      localStorageValueWithDateRange: true,
    });
  }

  public static byValue(
    searchValue: string,
    defaultValue?: ModelDateRange,
  ): ModelDateRange | null {
    return this.by(this, ModelDateRange, 'value', searchValue, defaultValue);
  }

  public static byLocalStorageValue(
    searchLocalStorageValue: string,
    defaultValue?: ModelDateRange | null,
  ): ModelDateRange | null {
    if (searchLocalStorageValue) {
      const searchLocalStorageValueParts = searchLocalStorageValue.split(':');

      return (
        this.byValue(searchLocalStorageValueParts[0]) ||
        this.getCustomDate(
          DateUtils.fromApiDateString(searchLocalStorageValueParts[1]),
          DateUtils.fromApiDateString(searchLocalStorageValueParts[2]),
        )
      );
    }

    return defaultValue;
  }

  public static byFromAndTo(from: Dayjs, to: Dayjs): ModelDateRange {
    return find(this.ALL, {
      from,
      to,
    });
  }
}
