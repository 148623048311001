import { noop } from 'lodash';
import { Result } from 'antd';
import PropTypes from 'prop-types';

import ButtonCustom from 'common/components/button/ButtonCustom';
import { useI18n } from 'common/utils/hookI18n';
import { MAIN_BLUE_COLOR } from 'common/constants/constants';

function NetworkError({ onRetry }) {
  const i18n = useI18n();
  return (
    <Result
      status="error"
      title={i18n.t('error.network.title')}
      subTitle={i18n.t('error.network.description')}
      extra={[
        <ButtonCustom
          key="error-network-retry-button"
          type="primary"
          onClick={() => onRetry()}
          style={{ background: MAIN_BLUE_COLOR }}
          title={i18n.t('error.network.retry-button')}
        />,
      ]}
    />
  );
}

NetworkError.propTypes = {
  onRetry: PropTypes.func,
};

NetworkError.defaultProps = {
  onRetry: noop,
};

export default NetworkError;
