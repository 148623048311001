import i18next from 'i18next';
const i18n = i18next;

import TypeTreeLevel from 'common/models/enums/types/type-tree-level';
import AbstractModelHierarchy from 'common/models/hierarchies/abstract-hierarchy';

export const WORLD_HIERARCHY_ID = 'WORLD';
export const WORLD_PATH_FLAG = 'flags/world.jpg';

class WorldHierarchy extends AbstractModelHierarchy {
  constructor() {
    super(WORLD_HIERARCHY_ID, TypeTreeLevel.PAYS, () => i18n.t('company.name'));
  }

  public get idUnique(): string {
    return this.label;
  }

  public toString(): string {
    return `${this.id} - ${this.label}`;
  }
}

export const WORLD_HIERARCHY = new WorldHierarchy();
