import i18next from 'i18next';
const i18n = i18next;
import { VpPieChartIcon } from '@vtmn-play/icons/react';

import ModelFilters from 'common/models/ModelFilters';
import { IModelTable } from 'common/models/interfaces/table';
import ModelToolbar from 'common/models/ModelToolbar';
import { generateParams } from 'common/utils/navigation';
import { IPageParams } from 'common/models/interfaces/popup-selector';

const getLinkBySelectors = (
  filters: ModelFilters,
  table: IModelTable,
  toolbar: ModelToolbar,
  pageParams: IPageParams,
) => {
  let link = '/overview';

  link += generateParams(filters, table, toolbar, pageParams);

  return link;
};

export default {
  id: 'overview',
  title: () => i18n.t(`navigation.label.overview`),
  icon: <VpPieChartIcon style={{ fontSize: '3rem', marginLeft: '-4px' }} />,
  order: 0,
  link: getLinkBySelectors,
  paths: ['/overview'],
  subLinks: null,
  isNotAccessibleForFranchised: false,
};
