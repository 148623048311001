const slicePrefix = 'global/';

export const AddFavoriteStore = `${slicePrefix}addFavoriteStore`;
export type TypeAddFavoriteStore = {
  profile: number;
  store: number;
  stores: number[];
};

export const AddFavoriteEcommerce = `${slicePrefix}addFavoriteEcommerce`;
export type TypeAddFavoriteEcommerce = {
  profile: number;
  ecommerce: number;
};

export const AddFavoriteCountry = `${slicePrefix}addFavoriteCountry`;
export type TypeAddFavoriteCountry = {
  profile: number;
  country: string;
};

export const AddFavoriteDepartment = `${slicePrefix}addFavoriteDepartment`;
export type TypeAddFavoriteDepartment = {
  profile: number;
  department: number;
  departments: number[];
};

export const AddFavoriteSubDepartment =
  slicePrefix + 'addFavoriteSubDepartment';
export type TypeAddFavoriteSubDepartment = {
  profile: number;
  subDepartment: number;
};

export const AddFavoriteFamily = `${slicePrefix}addFavoriteFamily`;
export type TypeAddFavoriteFamily = {
  profile: number;
  family: number;
};

export const AddFavoriteModel = `${slicePrefix}addFavoriteModel`;
export type TypeAddFavoriteModel = {
  profile: number;
  model: number;
};

export const ClearFavoriteStore = `${slicePrefix}clearFavoriteStore`;
export type TypeClearFavoriteStore = {
  profile: number;
};

export const RemoveFavoriteStore = `${slicePrefix}removeFavoriteStore`;
export type TypeRemoveFavoriteStore = {
  profile: number;
  store: number;
};

export const ClearFavoriteEcommerce = `${slicePrefix}clearFavoriteEcommerce`;
export type TypeClearFavoriteEcommerce = {
  profile: number;
};
export const RemoveFavoriteEcommerce = slicePrefix + 'removeFavoriteEcommerce';
export type TypeRemoveFavoriteEcommerce = {
  profile: number;
  ecommerce: number;
};

export const ClearFavoriteCountry = `${slicePrefix}clearFavoriteCountry`;
export type TypeClearFavoriteCountry = {
  profile: number;
};
export const RemoveFavoriteCountry = `${slicePrefix}removeFavoriteCountry`;
export type TypeRemoveFavoriteCountry = {
  profile: number;
  country: string;
};

export const ClearFavoriteDepartment = `${slicePrefix}clearFavoriteDepartment`;
export type TypeClearFavoriteDepartment = {
  profile: number;
};

export const RemoveFavoriteDepartment = `${slicePrefix}removeFavoriteDepartment`;
export type TypeRemoveFavoriteDepartment = {
  profile: number;
  department: number;
};

export const ClearFavoriteSubDepartment = `${slicePrefix}clearFavoriteSubDepartment`;
export type TypeClearFavoriteSubDepartment = {
  profile: number;
};
export const RemoveFavoriteSubDepartment = `${slicePrefix}removeFavoriteSubDepartment`;
export type TypeRemoveFavoriteSubDepartment = {
  profile: number;
  subDepartment: number;
};

export const ClearFavoriteFamily = `${slicePrefix}clearFavoriteFamily`;
export type TypeClearFavoriteFamily = {
  profile: number;
};
export const RemoveFavoriteFamily = `${slicePrefix}removeFavoriteFamily`;
export type TypeRemoveFavoriteFamily = {
  profile: number;
  family: number;
};

export const ClearFavoriteModel = `${slicePrefix}clearFavoriteModel`;
export type TypeClearFavoriteModel = {
  profile: number;
};

export const RemoveFavoriteModel = `${slicePrefix}removeFavoriteModel`;
export type TypeRemoveFavoriteModel = {
  profile: number;
  model: number;
};

export type ActionTypesRemoveFavorite =
  | TypeRemoveFavoriteCountry
  | TypeRemoveFavoriteStore
  | TypeRemoveFavoriteEcommerce
  | ActionTypesRemoveFavoriteProduct;

export type ActionTypesRemoveFavoriteProduct =
  | TypeRemoveFavoriteDepartment
  | TypeRemoveFavoriteSubDepartment
  | TypeRemoveFavoriteFamily
  | TypeRemoveFavoriteModel;

export type ActionTypesAddFavorite =
  | ActionTypesAddFavoriteTier
  | ActionTypesAddFavoriteProduct;

export type ActionTypesAddFavoriteTier =
  | TypeAddFavoriteCountry
  | ActionTypesAddFavoriteStoreLike;

export type ActionTypesAddFavoriteStoreLike =
  | TypeAddFavoriteStore
  | TypeAddFavoriteEcommerce;

export type ActionTypesAddFavoriteProduct =
  | TypeAddFavoriteDepartment
  | TypeAddFavoriteSubDepartment
  | TypeAddFavoriteFamily
  | TypeAddFavoriteModel;
