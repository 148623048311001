import { Dispatch } from 'redux';
import LocalStorageUtils from 'common/utils/local-storage';
import { TIME_WEEK } from 'common/constants/time';
import { updateUserSettings } from 'common/reducers/userSettings';

export type UserSettings = {
  locale: string;
};

function persistUserSettings(userSettings: UserSettings): void {
  return LocalStorageUtils.setItem(
    'userSettings',
    JSON.stringify(userSettings),
    TIME_WEEK,
  );
}

export function updateUserSettingsAndPersist(
  userSettings: UserSettings,
  dispatch: Dispatch,
): void {
  dispatch(updateUserSettings(userSettings));
  persistUserSettings(userSettings);
}
