import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserSettings } from 'common/actions/user-settings-actions';

const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState: {
    locale: 'en',
  },
  reducers: {
    updateUserSettings: (state, action: PayloadAction<UserSettings>) => {
      state.locale = action.payload.locale;
    },
  },
});

export const { updateUserSettings } = userSettingsSlice.actions;
export default userSettingsSlice.reducer;
