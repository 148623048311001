import Optional from 'common/models/types/optional';
import DropValue, { OptionnalDropValue } from 'common/models/types/drop-value';

export function toCapitalizeWorld(label) {
  return label
    .toLowerCase()
    .replace(/(^|((?!\w|[\u0080-\u00FF]).))(\w|[\u0080-\u00FF])/g, (l) =>
      l.toUpperCase(),
    );
}

export function isDefineAndNotNaN(a: number | undefined) {
  return typeof a !== 'undefined' && a !== null && !Number.isNaN(a);
}

export function getClosestParentBySelector(el, selector) {
  let matchesFn;
  // find vendor prefix
  [
    'matches',
    'webkitMatchesSelector',
    'mozMatchesSelector',
    'msMatchesSelector',
    'oMatchesSelector',
  ].some((fn) => {
    if (typeof document.body[fn] === 'function') {
      matchesFn = fn;
      return true;
    }
    return false;
  });

  let parent;

  // traverse parents
  while (el) {
    parent = el.parentElement;
    if (parent?.[matchesFn](selector)) {
      return parent;
    }
    el = parent;
  }

  return null;
}

export function getValueOrDefaultOrDrop<T>(
  value: OptionnalDropValue<T>,
  defaultValue: Optional<T>,
): Optional<T> {
  if (value !== undefined && value !== null) {
    return value instanceof DropValue ? null : value;
  }
  return defaultValue;
}

export function getValueOrDefaultOrEmptyArray<T>(
  value: OptionnalDropValue<T[]>,
  defaultValue: Optional<T[]>,
): Optional<T[]> {
  if (value !== undefined && value !== null) {
    return value instanceof DropValue ? [] : value;
  }
  return defaultValue;
}
