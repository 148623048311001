import i18next from 'i18next';
const i18n = i18next;

export default class ModelTableViewOption {
  public readonly value: string;

  public readonly i18nKey: string;

  public readonly isDefault: boolean;

  public readonly weight: number;

  public readonly bffParam: string;

  constructor(
    value: string,
    i18nKey: string,
    bffParam: string,
    weight: number,
    isDefault = false,
  ) {
    this.value = value;
    this.i18nKey = i18nKey;
    this.isDefault = isDefault;
    this.weight = weight;
    this.bffParam = bffParam || value;
  }

  public get label(): string {
    return i18n.t(`application.data.view.${this.i18nKey}`);
  }
}
