import { useNavigate } from 'react-router-dom';

import { useI18n } from 'common/utils/hookI18n';

import { VtmnButton } from '@vtmn/react';

interface IErrorPageProps {
  readonly title: string;
  readonly message: string;
}

function ErrorPage({ title, message }: IErrorPageProps) {
  const navigate = useNavigate();
  const i18n = useI18n();

  const onClick = () => {
    navigate('/');
  };

  return (
    <div className="content-grid container-fluid not-found">
      <div className="row parent-headband">
        <div className="col-sm-12">
          <h3>{i18n.t(title)}</h3>
        </div>
      </div>
      <div className="row content">
        <div className="col-sm-12">
          <p>{i18n.t(message)}</p>
          <p
            style={{
              paddingTop: '1rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <VtmnButton onClick={onClick}>
              {i18n.t('error.http.back-to-overview')}
            </VtmnButton>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
