import i18next from 'i18next';
const i18n = i18next;

export default class ModelIncrease {
  public readonly value: string;

  public readonly keyI18n: string;

  private readonly keyI18nTitleTable: string;

  public readonly bffKey: string;

  constructor(
    value: string,
    keyI18n: string,
    keyI18nTitleTable: string,
    bffKey: string,
  ) {
    this.value = value;
    this.bffKey = bffKey;
    this.keyI18n = keyI18n;
    this.keyI18nTitleTable = keyI18nTitleTable;
  }

  public toString(): string {
    return this.label;
  }

  public get id(): string {
    return this.value;
  }

  public get label(): string {
    return i18n.t(this.keyI18n);
  }

  public get titleTable(): string {
    return i18n.t(this.keyI18nTitleTable);
  }
}
