import {
  AddFavoriteCountry,
  AddFavoriteDepartment,
  AddFavoriteEcommerce,
  AddFavoriteFamily,
  AddFavoriteModel,
  AddFavoriteStore,
  AddFavoriteSubDepartment,
  ClearFavoriteCountry,
  ClearFavoriteDepartment,
  ClearFavoriteEcommerce,
  ClearFavoriteFamily,
  ClearFavoriteModel,
  ClearFavoriteStore,
  ClearFavoriteSubDepartment,
  RemoveFavoriteCountry,
  RemoveFavoriteDepartment,
  RemoveFavoriteEcommerce,
  RemoveFavoriteFamily,
  RemoveFavoriteModel,
  RemoveFavoriteStore,
  RemoveFavoriteSubDepartment,
} from 'pages/favorites/constants/action-types';

import AbstractEnumType from 'common/models/enums/types/abstract-enum-type';
import ModelTypeTreeLevel from 'common/models/enums/models/type-tree-level';
import TypeTableAggregationEnum from 'common/models/enums/types/TypeTableAggregationEnum';

export default class TypeTreeLevel extends AbstractEnumType {
  public static readonly PAYS = new ModelTypeTreeLevel({
    name: 'COUNTRY',
    value: 15,
    aggLevel: 'countries',
    i18n: 'country',
    addFavoriteActionType: AddFavoriteCountry,
    removeFavoriteActionType: RemoveFavoriteCountry,
    clearFavoriteActionType: ClearFavoriteCountry,
    paramLink: 'codePays',
    parent: null,
    labelAnalytics: null,
    urlKey: 'countries',
    showIdInTable: false,
    jsKeySingle: 'country',
    jsKeyPlural: 'countries',
    urlLabel: null,
    aggregation: TypeTableAggregationEnum.DEPARTMENTS,
    urlParam: 'byCountries',
  });

  public static readonly REGION = new ModelTypeTreeLevel({
    name: 'REGION',
    value: 13,
    aggLevel: 'regions',
    i18n: 'region',
    addFavoriteActionType: null,
    removeFavoriteActionType: null,
    clearFavoriteActionType: null,
    paramLink: 'idStore',
    parent: TypeTreeLevel.PAYS,
    labelAnalytics: 'region',
    urlKey: 'regions',
    showIdInTable: true,
    jsKeySingle: 'region',
    jsKeyPlural: 'regions',
    urlLabel: 'tree/agglo',
    aggregation: TypeTableAggregationEnum.DEPARTMENTS,
    urlParam: 'byRegions',
  });

  public static readonly STORE = new ModelTypeTreeLevel({
    name: 'STORE',
    value: 7,
    aggLevel: 'stores',
    i18n: 'store',
    addFavoriteActionType: AddFavoriteStore,
    removeFavoriteActionType: RemoveFavoriteStore,
    clearFavoriteActionType: ClearFavoriteStore,
    paramLink: 'idStore',
    parent: TypeTreeLevel.PAYS,
    labelAnalytics: 'physical store',
    urlKey: 'stores',
    showIdInTable: true,
    jsKeySingle: 'store',
    jsKeyPlural: 'stores',
    urlLabel: null,
    aggregation: TypeTableAggregationEnum.DEPARTMENTS,
    urlParam: 'byThirds',
  });

  public static readonly ECOMMERCE = new ModelTypeTreeLevel({
    name: 'ECOMMERCE',
    value: 50,
    aggLevel: 'stores',
    i18n: 'ecommerce',
    addFavoriteActionType: AddFavoriteEcommerce,
    removeFavoriteActionType: RemoveFavoriteEcommerce,
    clearFavoriteActionType: ClearFavoriteEcommerce,
    paramLink: 'idStore',
    parent: TypeTreeLevel.PAYS,
    labelAnalytics: 'ecommerce store',
    urlKey: null,
    showIdInTable: true,
    jsKeySingle: 'ecommerce',
    jsKeyPlural: 'ecommerces',
    urlLabel: null,
    aggregation: TypeTableAggregationEnum.DEPARTMENTS,
    urlParam: 'byThirds',
  });

  public static readonly DEPARTMENT = new ModelTypeTreeLevel({
    name: 'DEPARTMENT',
    value: 4,
    aggLevel: 'departments',
    i18n: 'department',
    addFavoriteActionType: AddFavoriteDepartment,
    removeFavoriteActionType: RemoveFavoriteDepartment,
    clearFavoriteActionType: ClearFavoriteDepartment,
    paramLink: 'idDepartment',
    parent: null,
    labelAnalytics: 'sport',
    urlKey: 'sports',
    showIdInTable: null,
    jsKeySingle: 'department',
    jsKeyPlural: 'departments',
    urlLabel: 'labels/product',
    aggregation: TypeTableAggregationEnum.GEOGRAPHICAL,
    urlParam: 'byDepartments',
  });

  public static readonly SUB_DEPARTMENT = new ModelTypeTreeLevel({
    name: 'SUB_DEPARTMENT',
    value: 3,
    aggLevel: 'sub_departments',
    i18n: 'sub-department',
    addFavoriteActionType: AddFavoriteSubDepartment,
    removeFavoriteActionType: RemoveFavoriteSubDepartment,
    clearFavoriteActionType: ClearFavoriteSubDepartment,
    paramLink: 'idSubDepartment',
    parent: TypeTreeLevel.DEPARTMENT,
    labelAnalytics: 'sub_sports',
    urlKey: 'subSports',
    showIdInTable: null,
    jsKeySingle: 'subDepartment',
    jsKeyPlural: 'subDepartments',
    urlLabel: 'labels/product',
    aggregation: TypeTableAggregationEnum.GEOGRAPHICAL,
    urlParam: 'bySubDepartments',
  });

  public static readonly FAMILY = new ModelTypeTreeLevel({
    name: 'FAMILY',
    value: 2,
    aggLevel: 'families',
    i18n: 'family',
    addFavoriteActionType: AddFavoriteFamily,
    removeFavoriteActionType: RemoveFavoriteFamily,
    clearFavoriteActionType: ClearFavoriteFamily,
    paramLink: 'idFamily',
    parent: TypeTreeLevel.SUB_DEPARTMENT,
    labelAnalytics: 'family',
    urlKey: 'families',
    showIdInTable: null,
    jsKeySingle: 'family',
    jsKeyPlural: 'families',
    urlLabel: 'labels/product',
    aggregation: TypeTableAggregationEnum.GEOGRAPHICAL,
    urlParam: 'byFamilies',
  });

  public static readonly MODEL = new ModelTypeTreeLevel({
    name: 'MODEL',
    value: 1,
    aggLevel: 'models',
    i18n: 'model',
    addFavoriteActionType: AddFavoriteModel,
    removeFavoriteActionType: RemoveFavoriteModel,
    clearFavoriteActionType: ClearFavoriteModel,
    paramLink: 'idModel',
    parent: TypeTreeLevel.FAMILY,
    labelAnalytics: 'model',
    urlKey: 'models',
    showIdInTable: null,
    jsKeySingle: 'model',
    jsKeyPlural: 'models',
    urlLabel: 'labels/product',
    aggregation: TypeTableAggregationEnum.GEOGRAPHICAL,
    urlParam: 'byModels',
  });

  public static readonly ALL: ModelTypeTreeLevel[] = this.all(
    TypeTreeLevel,
    ModelTypeTreeLevel,
  );

  public static byValue(searchValue: number): ModelTypeTreeLevel {
    return this.by(this, ModelTypeTreeLevel, 'value', searchValue);
  }
}
