import '../style.css';

const CustomersPerf = () => {
  return (
    <div className="page-cp">
      <header className="header-cp">
        <h1 className="title-cp">DECATHLON</h1>
      </header>
      <main className="main-cp">
        <div className="item-cp">
          <span role="img" aria-label="English flag">
            🇬🇧
          </span>
          <p>
            Due to technical and resources constraints and priorities, Customers
            Performances has been stopped without any remplacement solution for
            United. A tool is available{' '}
            <a
              href="https://prod-uk-a.online.tableau.com/#/site/dktunited/views/Dashboardsuividestransationsidentifies/Suividestransactionsidentifies?:iid=1"
              target="_blank"
            >
              here
            </a>{' '}
            for France. We invite you to complete this form to share how you use
            the tool and how you are affected by this decision. We are sorry for
            the inconvenience.
          </p>
          <p>
            A form is a your disposal if you need more information{' '}
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfYnC4AJW1DKjYofbHmJpuenv8ehgT9bqcQPbG2EtGsjIxPbg/viewform"
              target="_blank"
            >
              here
            </a>
            .
          </p>
        </div>
        <div className="item-cp">
          <span role="img" aria-label="French flag">
            🇫🇷
          </span>
          <p>
            En raison de contraintes techniques, de ressources et de priorités,
            Customers Performances est décommisionné sans solution de
            remplacement pour United. Un outil est disponible{' '}
            <a
              href="https://prod-uk-a.online.tableau.com/#/site/dktunited/views/Dashboardsuividestransationsidentifies/Suividestransactionsidentifies?:iid=1"
              target="_blank"
            >
              ici
            </a>{' '}
            pour la France. Nous vous invitons à remplir ce formulaire pour
            partager comment vous utilisez l'outil et comment vous êtes affecté
            par cette décision. Nous sommes désolés pour le désagrément.
          </p>
          <p>
            Un formulaire est à votre disposition si vous avez besoin de plus
            d'informations{' '}
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfYnC4AJW1DKjYofbHmJpuenv8ehgT9bqcQPbG2EtGsjIxPbg/viewform"
              target="_blank"
            >
              ici
            </a>
            .
          </p>
        </div>
      </main>
      <footer className="footer-cp">
        <p>© 2025 Decathlon</p>
      </footer>
    </div>
  );
};

export default CustomersPerf;
