import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { isEqual } from 'lodash';

import dayjs from 'dayjs';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import store from 'common/store';

import Languages from 'common/i18n/languages';
import NavigatorUtils from 'common/utils/navigator';
import Maintenance from 'core/router/Maintenance';

import updateLocale from 'dayjs/plugin/updateLocale';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Authentication from './Authentication';

dayjs.extend(updateLocale);
dayjs.extend(customParseFormat);

dayjs.updateLocale(NavigatorUtils.getLanguage(), {
  weekStart: 7,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// Initialize i18next (if not already initialized)
if (!i18next.isInitialized) {
  i18next.use(initReactI18next).init({
    resources: Languages, // Load translations
    fallbackLng: 'en',
    lng: NavigatorUtils.getLanguage(),
    interpolation: {
      escapeValue: false,
    },
    missingKeyHandler: (_, __, key) => `Missing translation: ${key}`,
  });
}

function App() {
  useEffect(() => {
    const initializeTranslations = () => {
      // Add translations dynamically if needed
      Object.entries(Languages).forEach(([lang, translations]) => {
        i18next.addResourceBundle(lang, 'translation', translations);
      });
    };

    initializeTranslations();
  }, []);

  const isMaintenanceModeActive = isEqual(
    import.meta.env.VITE_MAINTENANCE_MODE,
    'on',
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        {isMaintenanceModeActive ? (
          <Maintenance />
        ) : (
          <BrowserRouter>
            <Authentication />
          </BrowserRouter>
        )}
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
