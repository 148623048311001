import { omit, reduce, without } from 'lodash';

import INITIAL_STATE from 'common/constants/initial-state-global';
import * as types from 'common/constants/action-types';
import * as FavoriteActionTypes from 'pages/favorites/constants/action-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ModelHierarchyCountry from 'common/models/hierarchies/ModelHierarchyCountry';
import ModelHierarchyStore from 'common/models/hierarchies/ModelHierarchyStore';

const globalSlice = createSlice({
  name: 'global',
  initialState: INITIAL_STATE,
  reducers: {
    // ------------------------------
    // HEADER / APP INIT / HIERARCHIES
    // ------------------------------

    changeHeader: (state, action: PayloadAction<types.ChangeHeaderType>) => {
      // Update nested header values directly (Immer handles immutability)
      state.header.propsPopupSelector = action.payload.propsPopupSelector;
      state.header.disabledFlag = action.payload.disabledFlag;
      state.header.liveDate = action.payload.liveDate || false;
      state.header.showFilters = action.payload.showFilters;
      state.header.infoBubbleFilters = action.payload.infoBubbleFilters;
    },

    initApp: (state, action: PayloadAction<types.InitAppType>) => {
      // Rebuild profiles using lodash’s reduce & omit
      const profiles = reduce(
        action.payload.profiles,
        (accumulator, profile) => ({
          ...accumulator,
          [profile.id]: {
            ...profile,
            content: profile.content && {
              ...omit(profile.content, 'sub_departments'),
              subDepartments: profile.content.sub_departments,
            },
          },
        }),
        {},
      );
      state.userInfo = action.payload.user_details;
      state.messages = action.payload.messages;
      state.profiles = profiles;
    },

    findHierarchies: (
      state,
      action: PayloadAction<types.FindHierarchiesType>,
    ) => {
      state.countries =
        (action.payload.countries as unknown as ModelHierarchyCountry[]) ||
        state.countries;
      state.stores =
        (action.payload.stores as unknown as ModelHierarchyStore[]) ||
        state.stores;
      state.departments = action.payload.departments || state.departments;
      state.subDepartments =
        action.payload.subDepartments || state.subDepartments;
      state.families = action.payload.families || state.families;
      state.models = action.payload.models || state.models;
    },

    // ------------------------------
    // FAVORITES – ADD / REMOVE / CLEAR
    // ------------------------------

    addFavoriteCountry: (
      state,
      action: PayloadAction<FavoriteActionTypes.TypeAddFavoriteCountry>,
    ) => {
      const profileId = action.payload.profile;
      const profile = state.profiles[profileId];
      if (profile?.content) {
        profile.content.countries = [
          ...profile.content.countries,
          action.payload.country,
        ];
      }
    },

    addFavoriteStore: (
      state,
      action: PayloadAction<FavoriteActionTypes.TypeAddFavoriteStore>,
    ) => {
      const profileId = action.payload.profile;
      const profile = state.profiles[profileId];
      if (profile?.content) {
        if (action.payload.store) {
          profile.content.stores = [
            ...profile.content.stores,
            action.payload.store,
          ];
        } else if (action.payload.stores) {
          profile.content.stores = [
            ...profile.content.stores,
            ...action.payload.stores,
          ];
        }
      }
    },

    addFavoriteEcommerce: (
      state,
      action: PayloadAction<FavoriteActionTypes.TypeAddFavoriteEcommerce>,
    ) => {
      const profileId = action.payload.profile;
      const profile = state.profiles[profileId];
      if (profile?.content) {
        profile.content.ecommerces = [
          ...profile.content.ecommerces,
          action.payload.ecommerce,
        ];
      }
    },

    addFavoriteDepartment: (
      state,
      action: PayloadAction<FavoriteActionTypes.TypeAddFavoriteDepartment>,
    ) => {
      const profileId = action.payload.profile;
      const profile = state.profiles[profileId];
      if (profile?.content) {
        if (action.payload.department) {
          profile.content.departments = [
            ...profile.content.departments,
            action.payload.department,
          ];
        } else if (action.payload.departments) {
          profile.content.departments = [
            ...profile.content.departments,
            ...action.payload.departments,
          ];
        }
      }
    },

    addFavoriteSubDepartment: (
      state,
      action: PayloadAction<FavoriteActionTypes.TypeAddFavoriteSubDepartment>,
    ) => {
      const profileId = action.payload.profile;
      const profile = state.profiles[profileId];
      if (profile?.content) {
        profile.content.subDepartments = [
          ...profile.content.subDepartments,
          action.payload.subDepartment,
        ];
      }
    },

    addFavoriteFamily: (
      state,
      action: PayloadAction<FavoriteActionTypes.TypeAddFavoriteFamily>,
    ) => {
      const profileId = action.payload.profile;
      const profile = state.profiles[profileId];
      if (profile?.content) {
        profile.content.families = [
          ...profile.content.families,
          action.payload.family,
        ];
      }
    },

    addFavoriteModel: (
      state,
      action: PayloadAction<FavoriteActionTypes.TypeAddFavoriteModel>,
    ) => {
      const profileId = action.payload.profile;
      const profile = state.profiles[profileId];
      if (profile?.content) {
        profile.content.models = [
          ...profile.content.models,
          action.payload.model,
        ];
      }
    },

    removeFavoriteCountry: (
      state,
      action: PayloadAction<FavoriteActionTypes.TypeRemoveFavoriteCountry>,
    ) => {
      const profileId = action.payload.profile;
      const profile = state.profiles[profileId];
      if (profile?.content) {
        profile.content.countries = without(
          profile.content.countries,
          action.payload.country,
        );
      }
    },

    clearFavoriteCountry: (
      state,
      action: PayloadAction<FavoriteActionTypes.TypeClearFavoriteCountry>,
    ) => {
      const profileId = action.payload.profile;
      const profile = state.profiles[profileId];
      if (profile?.content) {
        profile.content.countries = [];
      }
    },

    removeFavoriteStore: (
      state,
      action: PayloadAction<FavoriteActionTypes.TypeRemoveFavoriteStore>,
    ) => {
      const profileId = action.payload.profile;
      const profile = state.profiles[profileId];
      if (profile?.content) {
        profile.content.stores = without(
          profile.content.stores,
          action.payload.store,
        );
      }
    },

    clearFavoriteStore: (
      state,
      action: PayloadAction<FavoriteActionTypes.TypeClearFavoriteStore>,
    ) => {
      const profileId = action.payload.profile;
      const profile = state.profiles[profileId];
      if (profile?.content) {
        profile.content.stores = [];
      }
    },

    removeFavoriteEcommerce: (
      state,
      action: PayloadAction<FavoriteActionTypes.TypeRemoveFavoriteEcommerce>,
    ) => {
      const profileId = action.payload.profile;
      const profile = state.profiles[profileId];
      if (profile?.content) {
        profile.content.ecommerces = without(
          profile.content.ecommerces,
          action.payload.ecommerce,
        );
      }
    },

    clearFavoriteEcommerce: (
      state,
      action: PayloadAction<FavoriteActionTypes.TypeClearFavoriteEcommerce>,
    ) => {
      const profileId = action.payload.profile;
      const profile = state.profiles[profileId];
      if (profile?.content) {
        profile.content.ecommerces = [];
      }
    },

    removeFavoriteDepartment: (
      state,
      action: PayloadAction<FavoriteActionTypes.TypeRemoveFavoriteDepartment>,
    ) => {
      const profileId = action.payload.profile;
      const profile = state.profiles[profileId];
      if (profile?.content) {
        profile.content.departments = without(
          profile.content.departments,
          action.payload.department,
        );
      }
    },

    clearFavoriteDepartment: (
      state,
      action: PayloadAction<FavoriteActionTypes.TypeClearFavoriteDepartment>,
    ) => {
      const profileId = action.payload.profile;
      const profile = state.profiles[profileId];
      if (profile?.content) {
        profile.content.departments = [];
      }
    },

    removeFavoriteSubDepartment: (
      state,
      action: PayloadAction<FavoriteActionTypes.TypeRemoveFavoriteSubDepartment>,
    ) => {
      const profileId = action.payload.profile;
      const profile = state.profiles[profileId];
      if (profile?.content) {
        profile.content.subDepartments = without(
          profile.content.subDepartments,
          action.payload.subDepartment,
        );
      }
    },

    clearFavoriteSubDepartment: (
      state,
      action: PayloadAction<FavoriteActionTypes.TypeClearFavoriteSubDepartment>,
    ) => {
      const profileId = action.payload.profile;
      const profile = state.profiles[profileId];
      if (profile?.content) {
        profile.content.subDepartments = [];
      }
    },

    removeFavoriteFamily: (
      state,
      action: PayloadAction<FavoriteActionTypes.TypeRemoveFavoriteFamily>,
    ) => {
      const profileId = action.payload.profile;
      const profile = state.profiles[profileId];
      if (profile?.content) {
        profile.content.families = without(
          profile.content.families,
          action.payload.family,
        );
      }
    },

    clearFavoriteFamily: (
      state,
      action: PayloadAction<FavoriteActionTypes.TypeClearFavoriteFamily>,
    ) => {
      const profileId = action.payload.profile;
      const profile = state.profiles[profileId];
      if (profile?.content) {
        profile.content.families = [];
      }
    },

    removeFavoriteModel: (
      state,
      action: PayloadAction<FavoriteActionTypes.TypeRemoveFavoriteModel>,
    ) => {
      const profileId = action.payload.profile;
      const profile = state.profiles[profileId];
      if (profile?.content) {
        profile.content.models = without(
          profile.content.models,
          action.payload.model,
        );
      }
    },

    clearFavoriteModel: (
      state,
      action: PayloadAction<FavoriteActionTypes.TypeClearFavoriteModel>,
    ) => {
      const profileId = action.payload.profile;
      const profile = state.profiles[profileId];
      if (profile?.content) {
        profile.content.models = [];
      }
    },

    // ------------------------------
    // PROFILES – LOAD / GET / SET DEFAULT / EDIT / DELETE / DUPLICATE
    // ------------------------------

    loadProfile: (state, action: PayloadAction<types.LoadProfileType>) => {
      const profileId = action.payload.profile;
      // Merge in new content for the profile (if the profile exists)
      if (state.profiles[profileId]) {
        state.profiles[profileId].content = action.payload.content;
      }
    },

    getProfilesSuccess: (
      state,
      action: PayloadAction<types.GetProfilesSuccessType>,
    ) => {
      state.profiles = reduce(
        action.payload.profiles,
        (accumulator, profile) => ({
          ...accumulator,
          [profile.id]: {
            ...profile,
            ...(state.profiles[profile.id] || {}),
          },
        }),
        {},
      );
    },

    setProfilesDefault: (
      state,
      action: PayloadAction<types.SetProfilesDefaultType>,
    ) => {
      const previousDefaultProfileId = Object.keys(state.profiles).find(
        (id) => state.profiles[id].by_default,
      );
      if (previousDefaultProfileId) {
        state.profiles[previousDefaultProfileId].by_default = false;
      }
      const newProfileId = action.payload.profile;
      if (state.profiles[newProfileId]) {
        state.profiles[newProfileId].by_default = true;
      }
    },

    editProfile: (state, action: PayloadAction<types.EditProfileType>) => {
      const profileId = action.payload.profile.id;
      if (state.profiles[profileId]) {
        state.profiles[profileId] = {
          ...state.profiles[profileId],
          ...action.payload.profile,
        };
      }
    },

    deleteProfile: (state, action: PayloadAction<types.DeleteProfileType>) => {
      delete state.profiles[action.payload.profile];
    },

    duplicateProfile: (
      state,
      action: PayloadAction<types.DuplicateProfileType>,
    ) => {
      state.profiles = reduce(
        action.payload.profiles,
        (acc, profile) => ({
          ...acc,
          [profile.id]: {
            ...profile,
            ...(state.profiles[profile.id] || {}),
          },
        }),
        {},
      );
    },
  },
});

export const {
  changeHeader,
  initApp,
  findHierarchies,
  addFavoriteCountry,
  addFavoriteStore,
  addFavoriteEcommerce,
  addFavoriteDepartment,
  addFavoriteSubDepartment,
  addFavoriteFamily,
  addFavoriteModel,
  removeFavoriteCountry,
  clearFavoriteCountry,
  removeFavoriteStore,
  clearFavoriteStore,
  removeFavoriteEcommerce,
  clearFavoriteEcommerce,
  removeFavoriteDepartment,
  clearFavoriteDepartment,
  removeFavoriteSubDepartment,
  clearFavoriteSubDepartment,
  removeFavoriteFamily,
  clearFavoriteFamily,
  removeFavoriteModel,
  clearFavoriteModel,
  loadProfile,
  getProfilesSuccess,
  setProfilesDefault,
  editProfile,
  deleteProfile,
  duplicateProfile,
} = globalSlice.actions;

export default globalSlice.reducer;
