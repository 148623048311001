import * as types from 'common/constants/action-types';
import INITIAL_STATE_AUTHENTICATION from 'common/constants/initial-state-authentication';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: INITIAL_STATE_AUTHENTICATION,
  reducers: {
    setUser: (state, action: PayloadAction<types.SetUserType>) => {
      state.user = action.payload.user;
    },
    resetUser: (state) => {
      state.user = null;
    },
  },
});

export const { setUser, resetUser } = authenticationSlice.actions;
export default authenticationSlice.reducer;
