import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty, isEqual, map, toInteger } from 'lodash';
import Axios, { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import i18next from 'i18next';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';

import frFR from 'antd/lib/locale/fr_FR';
import enEN from 'antd/lib/locale/en_US';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';

import AppRouter from 'core/router/Router';
import Unavailable from 'core/router/Unavailable';

import TypeStatusSearchData from 'common/models/enums/types/status-search-data';
import LogoLoader from 'common/components/loader/logo-loader';
import ModelStatusSearchData from 'common/models/enums/models/status-search-data';

import {
  STATUS_CODE_ACCEPTED,
  STATUS_CODE_BAD_REQUEST,
  STATUS_CODE_CONFLICT,
  STATUS_CODE_INTERNAL_SERVER_ERROR,
  STATUS_CODE_PARTIAL_CONTENT,
  STATUS_CODE_REQUEST_TIMEOUT,
  STATUS_CODE_TOO_MANY_REQUESTS,
  STATUS_CODE_UNAUTHORIZED,
  STATUS_CODE_URI_TOO_LONG,
} from 'common/constants/http-request';
import AppStore from 'common/store';
import TypeTreeLevel from 'common/models/enums/types/type-tree-level';
import Notify, { displayMessage } from 'common/utils/notify';
import ModelProductHierarchy from 'common/models/hierarchies/products/product-hierarchy';
import {
  loadInitRequest,
  loadLabelsRequest,
  updateAndPersistToolbar,
} from 'common/actions/global-actions';
import NetworkError from 'core/router/errors/NetworkError';
import ModelHierarchyCountry from 'common/models/hierarchies/ModelHierarchyCountry';
import { IStateApp } from 'common/reducers';
import ModelHierarchyStore from 'common/models/hierarchies/ModelHierarchyStore';
import ModelToolbar from 'common/models/ModelToolbar';
import { ModelToolbarLocalStorage } from 'common/models/interfaces/toolbar';
import LocalStorageUtils from 'common/utils/local-storage';
import {
  USER_FILTERS,
  USER_TABLE_OPTIONS,
  USER_TOOLBAR,
} from 'common/constants/cookies-keys';
import { loadColumns, loadSortBy, loadViews } from 'common/utils/table';
import ApiRetryException from 'common/api/ApiRetryException';
import ModelFilters from 'common/models/ModelFilters';
import { ModelFiltersLocalStorage } from 'common/models/interfaces/filters';
import { IModelLocalStorageTable } from 'common/models/interfaces/table';
import { useAuth } from 'oidc-react';
import Package from '../../package.json';
import { findHierarchies, initApp } from 'common/reducers/global';
import { updateFiltersAndPersist } from 'common/actions/filter-actions';
import { updateAndPersistTable } from 'common/actions/table-actions';
import {
  updateUserSettingsAndPersist,
  UserSettings,
} from 'common/actions/user-settings-actions';
import NavigatorUtils from 'common/utils/navigator';

const i18n = i18next;

function Initialization() {
  const dispatch = useDispatch();

  const { signOut } = useAuth();

  const [isNetworkFailed, setIsNetworkFailed] = useState(false);

  const [initStatus, setInitStatus] = useState<ModelStatusSearchData>(
    TypeStatusSearchData.WAITING,
  );
  const [labelsStatus, setLabelsStatus] = useState<ModelStatusSearchData>(
    TypeStatusSearchData.WAITING,
  );
  const [initializedApp, setInitializedApp] = useState(false);

  const { profiles, countries, stores, departments, subDepartments, families } =
    useSelector((state: IStateApp) => state.global);

  const { user } = useSelector((state: IStateApp) => state.authentication);
  const { locale } = useSelector((state: IStateApp) => state.userSettings);

  const initializeAxiosInterceptors = useCallback(() => {
    Axios.interceptors.response.use(
      (response) => {
        const statusCode = get(response, 'status');
        const config = get(response, 'config');

        if (
          config &&
          (statusCode === STATUS_CODE_PARTIAL_CONTENT ||
            statusCode === STATUS_CODE_ACCEPTED)
        ) {
          const numberOfRetries = config.headers['X-SP-Retries']
            ? toInteger(config.headers['X-SP-Retries']) + 1
            : 1;

          if (
            numberOfRetries >
            toInteger(import.meta.env.VITE_HTTP_RETRIES_PARTIAL_CONTENT)
          ) {
            throw new ApiRetryException(statusCode);
          }
          config.headers['X-SP-Retries'] = numberOfRetries;

          return new Promise((resolve, reject) => {
            setTimeout(() => {
              Axios.request(config).then(resolve).catch(reject);
            }, toInteger(import.meta.env.VITE_HTTP_RETRIES_INVERVAL_PARTIAL_CONTENT) * 1000);
          });
        }

        return response;
      },
      (error: AxiosError) => {
        const statusCode = get(error, 'response.status');
        const config = get(error, 'response.config');

        if (statusCode === STATUS_CODE_UNAUTHORIZED) {
          signOut();
          return null;
        }

        if (
          (config as any)?.isRetryableRequest &&
          statusCode === STATUS_CODE_CONFLICT
        ) {
          const numberOfRetries = config.headers['X-SP-Retries']
            ? toInteger(config.headers['X-SP-Retries']) + 1
            : 1;

          if (
            numberOfRetries > toInteger(import.meta.env.VITE_HTTP_MAX_RETRIES)
          ) {
            throw new ApiRetryException(STATUS_CODE_CONFLICT, error);
          }

          config.headers['X-SP-Retries'] = numberOfRetries;

          return new Promise((resolve, reject) => {
            setTimeout(() => {
              Axios.request(config).then(resolve).catch(reject);
            }, toInteger(import.meta.env.VITE_HTTP_RETRY_INVERVAL) * 1000);
          });
        }

        return Promise.reject(error);
      },
    );
  }, [signOut]);

  const initDatadogRum = useCallback(() => {
    const viteRumSampleRate = import.meta.env.VITE_RUM_SAMPLE_RATE;
    if (user && import.meta.env.MODE === 'production' && viteRumSampleRate) {
      datadogRum.init({
        applicationId: import.meta.env.VITE_DATADOG_ID,
        clientToken: import.meta.env.VITE_DATADOG_TOKEN,
        site: 'datadoghq.eu',
        service: 'sales-performances-front',
        version: Package.version,
        env: import.meta.env.VITE_DATADOG_ENV,
        sessionSampleRate: Number(viteRumSampleRate),
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
      });
      datadogRum.startSessionReplayRecording();
    }

    const jobName = (user.profile.jobname as string) ?? 'not-available';
    datadogRum.setUserProperty('job_name', jobName);
    datadogRum.setUser({
      id: user.profile.uid as string,
      country: user.profile.c as string,
      email: user.profile.mail as string,
      job_name: jobName,
    });
  }, [user]);

  const loadInitData = useCallback(() => {
    setIsNetworkFailed(false);
    loadInitRequest()
      .then((init) => {
        if (!init.data) {
          Notify.notify({
            title: null,
            content: i18n.t('error.init-error'),
            timeout: 5,
            type: 'error',
          });
          return;
        }

        if (isNetworkFailed) {
          setIsNetworkFailed(false);
        }

        initDatadogRum();

        AppStore.dispatch(initApp(init.data));

        setInitStatus(TypeStatusSearchData.FOUND);

        if (!isEmpty(init.data.messages)) {
          map(init.data.messages, (message) =>
            displayMessage(message, locale, 0),
          );
        }
      })
      .catch((err) => {
        const statusCode = get(err, 'response.status', null);

        if (!statusCode) {
          setInitStatus(TypeStatusSearchData.ERROR);
          return;
        }

        if (
          [
            STATUS_CODE_BAD_REQUEST,
            STATUS_CODE_CONFLICT,
            STATUS_CODE_REQUEST_TIMEOUT,
            STATUS_CODE_URI_TOO_LONG,
            STATUS_CODE_TOO_MANY_REQUESTS,
          ].includes(statusCode) ||
          statusCode >= STATUS_CODE_INTERNAL_SERVER_ERROR
        ) {
          setInitStatus(TypeStatusSearchData.ERROR);
          return;
        }

        datadogRum.addError(err);
        Notify.notify({
          title: null,
          content: i18n.t('error.init-error'),
          timeout: 5,
          type: 'error',
        });
      });
  }, [initDatadogRum, isNetworkFailed, locale]);

  const loadInitLabels = useCallback(() => {
    Promise.all([
      loadLabelsRequest(null),
      loadLabelsRequest(TypeTreeLevel.DEPARTMENT),
      loadLabelsRequest(TypeTreeLevel.SUB_DEPARTMENT),
      loadLabelsRequest(TypeTreeLevel.FAMILY),
    ])
      .then(
        ([
          labelsCountries,
          labelsDepartments,
          labelsSubDepartments,
          labelsFamilies,
        ]) => {
          if (isNetworkFailed) {
            setIsNetworkFailed(false);
          }

          AppStore.dispatch(
            findHierarchies({
              countries: labelsCountries.data.countries.map((r) =>
                ModelHierarchyCountry.fromApiModelHierarchy(r),
              ),
              stores: labelsCountries.data.stores.map((r) =>
                ModelHierarchyStore.fromApiModelHierarchy(r),
              ),
              departments: labelsDepartments.data.map((r) =>
                ModelProductHierarchy.fromApiModelProductHierarchy(r),
              ),
              subDepartments: labelsSubDepartments.data.map((r) =>
                ModelProductHierarchy.fromApiModelProductHierarchy(r),
              ),
              families: labelsFamilies.data.map((r) =>
                ModelProductHierarchy.fromApiModelProductHierarchy(r),
              ),
            }),
          );
          setLabelsStatus(TypeStatusSearchData.FOUND);
        },
      )
      .catch((err) => {
        const statusCode = get(err, 'response.status', null);

        if (!statusCode) {
          setLabelsStatus(TypeStatusSearchData.ERROR);
          return;
        }

        if (
          [
            STATUS_CODE_BAD_REQUEST,
            STATUS_CODE_CONFLICT,
            STATUS_CODE_REQUEST_TIMEOUT,
            STATUS_CODE_URI_TOO_LONG,
            STATUS_CODE_TOO_MANY_REQUESTS,
          ].includes(statusCode) ||
          statusCode >= STATUS_CODE_INTERNAL_SERVER_ERROR
        ) {
          setLabelsStatus(TypeStatusSearchData.ERROR);
          return;
        }

        datadogRum.addError(err);
        Notify.notify({
          title: null,
          content: i18n.t('error.init-error'),
          timeout: 5,
          type: 'error',
        });
      });
  }, [isNetworkFailed]);

  const launchInitializeToolbar = useCallback(() => {
    const urlParsed = new URLSearchParams(window.location.search);
    const localStorageItem: ModelToolbarLocalStorage = JSON.parse(
      LocalStorageUtils.getItem(USER_TOOLBAR),
    );

    const initializedToolbar = ModelToolbar.initialize({
      urlParsed,
      localStorage: localStorageItem,
      profiles,
      countries,
      stores,
      departments,
      subDepartments,
      families,
    });

    updateAndPersistToolbar(initializedToolbar, dispatch);
  }, [
    profiles,
    countries,
    stores,
    departments,
    subDepartments,
    families,
    dispatch,
  ]);

  const launchInitializeFilters = useCallback(() => {
    const urlParsed = new URLSearchParams(window.location.search);
    const localStorageItem: ModelFiltersLocalStorage = JSON.parse(
      LocalStorageUtils.getItem(USER_FILTERS),
    );

    const initializedFilters = ModelFilters.initialize(
      urlParsed,
      localStorageItem,
    );

    updateFiltersAndPersist(initializedFilters, dispatch);
  }, [dispatch]);

  const launchInitializeTableOptions = useCallback(() => {
    const url = window.location;
    const urlParsed = new URLSearchParams(url.search);

    const localStorageTable: IModelLocalStorageTable = JSON.parse(
      LocalStorageUtils.getItem(USER_TABLE_OPTIONS),
    );

    updateAndPersistTable(
      {
        columns: loadColumns(urlParsed, localStorageTable),
        viewOptions: loadViews(urlParsed, localStorageTable),
        sortBy: loadSortBy(urlParsed, localStorageTable),
        fixHeader: localStorageTable?.fixHeader,
        showBorder: localStorageTable?.showBorder,
        paginate: localStorageTable?.paginate,
      },
      dispatch,
    );
  }, [dispatch]);

  const InitializeViewGmvPopup = () =>
    LocalStorageUtils.setItem('viewGmv', 'false');

  const antLocale = useCallback(() => {
    if (locale === 'fr') {
      dayjs.locale('fr');
      return frFR;
    }
    dayjs.locale('en');
    return enEN;
  }, [locale]);

  const launchInitializeUserSettings = useCallback(() => {
    const navigatorLocale = NavigatorUtils.getLanguage() === 'fr' ? 'fr' : 'en';

    const userSettings: UserSettings = JSON.parse(
      LocalStorageUtils.getItem('userSettings'),
    ) || { locale: navigatorLocale };
    updateUserSettingsAndPersist(userSettings, dispatch);
  }, [dispatch]);

  useEffect(() => {
    i18next.changeLanguage(locale); // Switch the locale in i18next
  }, [locale]);

  useEffect(() => {
    initializeAxiosInterceptors();
  }, [initializeAxiosInterceptors]);

  useEffect(() => {
    loadInitData();
    loadInitLabels();
  }, [loadInitData, loadInitLabels]);

  useEffect(() => {
    if (initializedApp) {
      launchInitializeToolbar();
    }
  }, [initializedApp, launchInitializeToolbar]);

  useEffect(() => {
    if (
      isEqual(initStatus, TypeStatusSearchData.FOUND) &&
      isEqual(labelsStatus, TypeStatusSearchData.FOUND)
    ) {
      launchInitializeUserSettings();
      launchInitializeToolbar();
      launchInitializeFilters();
      launchInitializeTableOptions();
      // eslint-disable-next-line
      !LocalStorageUtils.getItem('viewGmv') && InitializeViewGmvPopup();
      setInitializedApp(true);
    }
  }, [
    initStatus,
    labelsStatus,
    launchInitializeFilters,
    launchInitializeUserSettings,
    launchInitializeTableOptions,
    launchInitializeToolbar,
  ]);

  useEffect(() => {
    // si on a une clé version dans le localstorage avec une date et que celle ci
    // n'est pas la même que celle dans la variable d'environnement, on clear le
    // localstorage et on met à jour la version
    if (
      import.meta.env.VITE_DATE_RELEASE_VERSION !==
        LocalStorageUtils.getItem('date_release_version') &&
      LocalStorageUtils.getItem('date_release_version')
    ) {
      LocalStorageUtils.clear();
      LocalStorageUtils.setItem(
        'date_release_version',
        import.meta.env.VITE_DATE_RELEASE_VERSION,
      );
    }
    if (!LocalStorageUtils.getItem('date_release_version')) {
      LocalStorageUtils.setItem(
        'date_release_version',
        import.meta.env.VITE_DATE_RELEASE_VERSION,
      );
    }
  }, []);

  const isUnavailable =
    !isNetworkFailed &&
    (isEqual(initStatus, TypeStatusSearchData.ERROR) ||
      isEqual(labelsStatus, TypeStatusSearchData.ERROR));

  return (
    <ConfigProvider
      locale={antLocale()}
      theme={{
        token: {
          colorPrimary: '#3643BA',
          colorPrimaryHover: '#00689D',
          colorPrimaryBorder: '#3643BA',
          colorPrimaryBorderHover: '#00689D',
        },
      }}
    >
      {isNetworkFailed && <NetworkError onRetry={loadInitData} />}

      {!isUnavailable && !isNetworkFailed && !initializedApp && (
        <div className="container-loader">
          <LogoLoader className="center-loader" />
        </div>
      )}

      {isUnavailable && <Unavailable />}
      {initializedApp && <AppRouter />}
    </ConfigProvider>
  );
}

export default Initialization;
