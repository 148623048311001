import i18next from 'i18next';
const i18n = i18next;

export const FUNCTIONAL_COLORS = {
  success: '#23a942',
  info: '#007dbc',
  warning: '#ff600a',
  error: '#e32c26',
};

export const MAIN_BLUE_COLOR = '#3643BA';

export const LIMIT_END_DATE = 366;
export const ONLINE = 'online';
export const PARENT = 'parent';
export const CHILD = 'child';

export const WIDTH_LARGE_COLLAPSE = 768;
export const WIDTH_COLLAPSE = 500;
export const DEVICE_MAX_WIDTH = 480;
export const NOTIFICATION_ALLOWED_HTML = [
  'a',
  'b',
  'strong',
  'i',
  'em',
  `ul`,
  `li`,
  `p`,
  'br',
];

export const START = 'start';
export const END = 'end';

export const IN_STORE = 'In store';
export const OUT_STORE = 'Out store';
export const IN_STORE_DATA = 'inStore';
export const IN_STORE_SHARE_DATA = 'inStoreShare';
export const IN_STORE_SHARE_RATE_DATA = 'inStoreShareRate';
export const IN_STORE_RATE_DATA = 'inStoreRate';
export const OUT_STORE_DATA = 'outStore';
export const OUT_STORE_SHARE_DATA = 'outStoreShare';
export const OUT_STORE_SHARE_RATE_DATA = 'outStoreShareRate';
export const OUT_STORE_RATE_DATA = 'outStoreRate';
export const OMNI_DATA = 'omni';
export const OMNI_SHARE_DATA = 'omniShare';
export const OMNI_SHARE_RATE_DATA = 'omniShareRate';
export const OMNI_RATE_DATA = 'omniRate';

export const COMPARABLE_DATE = 'comparable_date';

export const EXCLUDED_RATIO = ['marginRate', 'averageOrder'];

export const SORT_ARRAY_DESC = 'descend';
export const SORT_ARRAY_ASC = 'ascend';

export const ADMIN_COUNTRY_DISPLAY_METHOD = [
  {
    value: 'ALL',
    label: () => i18n.t('application.all'),
  },
  {
    value: 'ENABLED',
    label: () => i18n.t('application.activate'),
  },
  {
    value: 'DISABLED',
    label: () => i18n.t('application.deactivate'),
  },
];

export const ADMIN_MESSAGES_DISPLAY_METHOD = [
  {
    value: 'ACTIVE',
    label: () => i18n.t('application.active'),
  },
  {
    value: 'INACTIVE',
    label: () => i18n.t('application.inactive'),
  },
];

export const CACHE_METHOD = [
  { value: 'COUNTRY', label: () => 'Pays' },
  { value: 'DATE', label: () => 'Date' },
];

export const DATE_FORMAT = 'DD/MM/YYYY';

export const LINK_SAW =
  'https://support.decathlon.net/saw/ess/offeringPage/14894506';

export const GITHUB_CHANGELOG =
  'https://github.com/dktunited/sales-performances-front/blob/master/CHANGELOG.md';

export const SALES_PERFORMANCES_DOCUMENTATION =
  'https://sites.google.com/decathlon.com/salesperformances/';
export const SALES_PERFORMANCES_DOCUMENTATION_CAML =
  'https://sites.google.com/decathlon.com/salesperformances/how-to/tolm';

export const CUSTOMER_PERFORMANCES =
  'https://customers-performances.dktapp.cloud/';
export const MYGAME = 'https://mygame.decathlon.net/';

export const DATABOT_URL =
  'https://fr1.askr.ai/component/e46651a8-0512-4c29-8537-103627274f82/conversation';

export const SELECTED_OPTIONS_LIMIT = 20;
