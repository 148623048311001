import i18next from 'i18next';
const i18n = i18next;
import ModelTypeTreeLevel from 'common/models/enums/models/type-tree-level';
import ModelTableViewOption from './ModelTableViewOption';

type CallbackiIsValidTypeFor = (type: ModelTypeTreeLevel) => boolean;

export default class ModelSaleSupport {
  public readonly id: string;

  public readonly urlParam: string;

  public readonly weight: number;

  public readonly icon: string;

  public readonly type: ModelTableViewOption;

  public readonly isValidTypeFor: CallbackiIsValidTypeFor;

  public readonly isDigitalValue: boolean;

  public readonly enabled: boolean;

  private readonly keyI18n: string;

  constructor({
    id,
    urlParam,
    weight,
    keyI18n,
    icon,
    isValidTypeFor,
    type,
    digitalValue = false,
    enabled = true,
  }: {
    id: string;
    urlParam: string;
    weight: number;
    keyI18n: string;
    icon: string;
    isValidTypeFor: CallbackiIsValidTypeFor;
    type: ModelTableViewOption;
    digitalValue?: boolean;
    enabled?: boolean;
  }) {
    this.id = id;
    this.urlParam = urlParam || id;
    this.weight = weight;
    this.icon = icon;
    this.type = type;
    this.keyI18n = keyI18n;
    this.isValidTypeFor = isValidTypeFor;
    this.isDigitalValue = digitalValue;
    this.enabled = enabled;
  }

  public get label(): string {
    return i18n.t(this.keyI18n);
  }

  public toString(): string {
    return this.id;
  }
}
