import LogoLoader from '../../common/components/loader/logo-loader';
import ButtonCustom from '../../common/components/button/ButtonCustom';
import { useI18n } from '../../common/utils/hookI18n';

const Unavailable = () => {
  const i18n = useI18n();
  return (
    <div className={'maintenance-container'}>
      <div className={'maintenance'}>
        <p className="vtmn-typo_display-2">{i18n.t('application.title')}</p>
        <LogoLoader className={'center-loader'} />
        <p className="vtmn-typo_title-4">{i18n.t('error.unavailable.title')}</p>
        <p>{i18n.t('error.unavailable.description')}</p>
        <ButtonCustom
          type="primary"
          onClick={() => window.location.reload()}
          title={i18n.t('error.refresh-page-button')}
        />
      </div>
    </div>
  );
};
Unavailable.propTypes = {};
Unavailable.defaultProps = {};
export default Unavailable;
