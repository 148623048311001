import i18next from 'i18next';
const i18n = i18next;

export default class ModelDateTypeComparable {
  public readonly value: string;

  private readonly keyI18n: string;

  constructor(value: string, label: string) {
    this.value = value;
    this.keyI18n = label;
  }

  public get label(): string {
    return i18n.t(this.keyI18n);
  }
}
