import i18next from 'i18next';
const i18n = i18next;
import { VtmnIcon } from '@vtmn/react';

import { generateParams } from 'common/utils/navigation';
import ModelFilters from 'common/models/ModelFilters';
import { IModelTable } from 'common/models/interfaces/table';
import ModelToolbar from 'common/models/ModelToolbar';
import { IPageParams } from 'common/models/interfaces/popup-selector';

const getLinkBySelectors = (
  filters: ModelFilters,
  table: IModelTable,
  toolbar: ModelToolbar,
  pageParams: IPageParams,
) => {
  let link = '/storesFromRegions';

  link += generateParams(filters, table, toolbar, pageParams);

  return link;
};

export default {
  title: () => i18n.t(`navigation.label.storesFromAgglo`),
  id: 'storesFromRegions',
  icon: <VtmnIcon value="map-pin-line" />,
  order: 3,
  link: getLinkBySelectors,
  paths: ['/storesFromRegions'],
  subLinks: null,
};
