import { isEqual } from 'lodash';

import AbstractEnumType from 'common/models/enums/types/abstract-enum-type';
import ModelSaleSupport from 'common/models/enums/models/sale-support';
import TypeTreeLevel from 'common/models/enums/types/type-tree-level';
import TypeTableViewOptionEnum from './TypeTableViewOptionEnum';

export default class TypeSaleSupport extends AbstractEnumType {
  public static readonly PHYSICAL_STORE = new ModelSaleSupport({
    id: 'physicalStore',
    urlParam: 'physical_store',
    weight: 0,
    keyI18n: 'application.filter.sales-support.physical-store',
    icon: 'shopping-cart-line',
    isValidTypeFor: (type) => !isEqual(TypeTreeLevel.ECOMMERCE, type),
    type: TypeTableViewOptionEnum.IN_STORE,
  });

  public static readonly DIGITAL_STORE = new ModelSaleSupport({
    id: 'digitalStore',
    urlParam: 'digital_store',
    weight: 1,
    keyI18n: 'application.filter.sales-support.digital-store',
    icon: 'add-circle-line',
    isValidTypeFor: (type) => !isEqual(TypeTreeLevel.ECOMMERCE, type),
    type: TypeTableViewOptionEnum.IN_STORE,
    digitalValue: true,
  });

  public static readonly DECAPRO_IN = new ModelSaleSupport({
    id: 'decaproInStore',
    urlParam: 'decapro_in_store',
    weight: 2,
    keyI18n: 'application.filter.sales-support.decaproIn',
    icon: 'suitcase-line',
    isValidTypeFor: (type) => !isEqual(TypeTreeLevel.ECOMMERCE, type),
    type: TypeTableViewOptionEnum.IN_STORE,
    digitalValue: true,
  });

  public static readonly CLICK_COLLECT = new ModelSaleSupport({
    id: 'clickAndCollect',
    urlParam: 'click_and_collect',
    weight: 3,
    keyI18n: 'application.filter.sales-support.c&c',
    icon: 'tablet-line',
    isValidTypeFor: (type) => !isEqual(TypeTreeLevel.ECOMMERCE, type),
    type: TypeTableViewOptionEnum.OUT_STORE,
    digitalValue: true,
  });

  public static readonly INTERNET = new ModelSaleSupport({
    id: 'internet',
    urlParam: null,
    weight: 4,
    keyI18n: 'application.filter.sales-support.internet',
    icon: 'macbook-line',
    isValidTypeFor: (type) => !isEqual(TypeTreeLevel.ECOMMERCE, type),
    type: TypeTableViewOptionEnum.OUT_STORE,
    digitalValue: true,
  });

  public static readonly ECOMMERCE = new ModelSaleSupport({
    id: 'ecommerce',
    urlParam: null,
    weight: 5,
    keyI18n: 'application.filter.sales-support.ecommerce',
    icon: 'link-line',
    isValidTypeFor: (type) => !isEqual(TypeTreeLevel.STORE, type),
    type: TypeTableViewOptionEnum.OUT_STORE,
    digitalValue: true,
  });

  public static readonly DECAPRO_OUT = new ModelSaleSupport({
    id: 'decaproOutStore',
    urlParam: 'decapro_out_store',
    weight: 6,
    keyI18n: 'application.filter.sales-support.decaproOut',
    icon: 'suitcase-line',
    isValidTypeFor: (type) => !isEqual(TypeTreeLevel.ECOMMERCE, type),
    type: TypeTableViewOptionEnum.OUT_STORE,
    digitalValue: true,
  });

  public static readonly ALL = this.all<ModelSaleSupport>(
    TypeSaleSupport,
    ModelSaleSupport,
  )
    .filter((saleSupport) => saleSupport.enabled)
    .sort((a, b) => a.weight - b.weight);

  public static readonly ALL_INSTORE = this.all<ModelSaleSupport>(
    TypeSaleSupport,
    ModelSaleSupport,
  )
    .filter(
      (saleSupport) =>
        saleSupport.enabled &&
        saleSupport.type === TypeTableViewOptionEnum.IN_STORE,
    )
    .sort((a, b) => a.weight - b.weight);

  public static readonly ALL_OUTSTORE = this.all<ModelSaleSupport>(
    TypeSaleSupport,
    ModelSaleSupport,
  )
    .filter(
      (saleSupport) =>
        saleSupport.enabled &&
        saleSupport.type === TypeTableViewOptionEnum.OUT_STORE,
    )
    .sort((a, b) => a.weight - b.weight);

  public static isEqualSalesSupport(
    salesSupport: ModelSaleSupport[],
    comparedValues: ModelSaleSupport[],
  ): boolean {
    const salesSupportToStrings = comparedValues
      ? salesSupport.map((saleSupport) => saleSupport.id)
      : null;

    return isEqual(salesSupportToStrings, comparedValues);
  }

  public static byId(searchId: string): ModelSaleSupport {
    return this.by(this, ModelSaleSupport, 'id', searchId);
  }
}
