import i18next from 'i18next';
const i18n = i18next;
import { find, isEqual, orderBy } from 'lodash';

import TypeTreeLevel from 'common/models/enums/types/type-tree-level';
import ModelTypeTreeLevel from 'common/models/enums/models/type-tree-level';
import StringOrNumberType from 'common/models/types/string-number';

import { toCapitalizeWorld } from 'common/utils/global';
import CallbackString from 'common/models/types/callback-string';
import { ISelectOption } from 'common/models/interfaces/select-search-option';
import ModelProductHierarchy from 'common/models/hierarchies/products/product-hierarchy';

export default class AbstractModelProductHierarchy {
  public readonly label: string;

  public readonly id: StringOrNumberType;

  public readonly level: ModelTypeTreeLevel;

  constructor(
    id: StringOrNumberType,
    level: number,
    label: string | CallbackString,
  ) {
    this.id = id;
    this.level = TypeTreeLevel.byValue(level);

    this.label = this.constructlLabel(
      typeof label === 'function' ? label() : label,
    );
  }

  public get idUnique(): string {
    return `${this.level.value}-${this.id}`;
  }

  public get labelWithId(): string {
    return `${this.id} - ${this.label}`;
  }

  protected constructlLabel(label: string): string {
    return label ? toCapitalizeWorld(label) : this.undefinedLabel;
  }

  protected get undefinedLabel() {
    return i18n.t('application.label.undefined-id', { id: this.id });
  }

  public isEqual(productHierarchy: AbstractModelProductHierarchy): boolean {
    return (
      productHierarchy &&
      productHierarchy.id === this.id &&
      productHierarchy.level === this.level
    );
  }

  public toOption(customProperties?: Partial<ISelectOption>): ISelectOption {
    return {
      id: this.id,
      value: this.idUnique,
      label: this.labelWithId || this.id.toString(),
      level: this.level,
      ...customProperties,
    };
  }

  public static findHierarchy<T extends AbstractModelProductHierarchy>(
    hierarchies: T[],
    elementId: StringOrNumberType,
    elementType: ModelTypeTreeLevel,
  ): T {
    return find(
      hierarchies,
      (hierarchy) =>
        isEqual(hierarchy.id.toString(), elementId.toString()) &&
        isEqual(hierarchy.level, elementType),
    );
  }

  public static getHierarchiesOrdered(
    hierarchies: ModelProductHierarchy[],
  ): ModelProductHierarchy[] {
    return orderBy(hierarchies, 'label', 'asc');
  }
}
