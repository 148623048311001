import { ReactElement } from 'react';
import i18next from 'i18next';
const i18n = i18next;
import { notification } from 'antd';
import { Interweave } from 'interweave';

import { TIMESTAMP_SECOND } from 'common/constants/time';

import {
  FUNCTIONAL_COLORS,
  NOTIFICATION_ALLOWED_HTML,
} from 'common/constants/constants';
import ButtonCustom from 'common/components/button/ButtonCustom';

type NotificationType = 'info' | 'warning' | 'error' | 'success';

export interface INotifyMessage {
  type: NotificationType;
  title: () => string;
  content: () => string;
  timeout: number;
  onClick?: () => void;
  priority?: boolean;
}

export interface IMessages {
  id: number;
  en: string;
  fr;
  string;
  level: NotificationType;
  start_date: number;
  end_date: number;
  updated_at: number;
}

const defaultNotifyMessage: INotifyMessage = {
  type: 'error',
  title: () => i18n.t('error.global.title'),
  content: () => i18n.t('error.global.message'),
  timeout: TIMESTAMP_SECOND,
};

class Notify {
  public static notify({
    title,
    content,
    timeout = 5,
    type = defaultNotifyMessage.type,
    style,
    key,
    btn,
    hideIcon = false,
  }: {
    title?: ReactElement | string;
    content?: ReactElement | string;
    timeout?: number;
    type?: NotificationType;
    style?: React.CSSProperties;
    key?: string;
    btn?: ReactElement | string;
    hideIcon?: boolean;
  } = {}) {
    const titleOrDefault = !title
      ? i18n.t(`application.admin.messages.${type}`)
      : title;
    const messageOrDefault = !content ? (
      defaultNotifyMessage.content()
    ) : (
      <div style={{ whiteSpace: 'pre-wrap' }}>{content}</div>
    );

    notification[type]({
      message: titleOrDefault,
      description: messageOrDefault,
      duration: timeout, // in seconds
      style,
      btn,
      key,
      ...(hideIcon && { closeIcon: null }),
    });
  }
}

export const displayMessage = (message, locale, duration = 0) => {
  const key = `open${message.id}`;
  let title;
  const style = {
    color: 'black',
    borderRadius: '10px',
  };

  if (message.title) {
    title = message.title;
  } else {
    switch (message.level) {
      case 'info':
        title = i18n.t('application.admin.messages.info');
        break;
      case 'warning':
        title = i18n.t('application.admin.messages.warning');
        break;
      case 'error':
        title = i18n.t('application.admin.messages.error');
        break;
      case 'success':
        title = i18n.t('application.admin.messages.success');
        break;
      default:
        title = i18n.t('application.admin.messages.info');
        break;
    }
  }

  Notify.notify({
    title: (
      <span style={{ color: FUNCTIONAL_COLORS[message.level] }}>{title}</span>
    ),
    content: (
      <Interweave
        content={message[locale]}
        allowList={NOTIFICATION_ALLOWED_HTML}
      />
    ),
    timeout: duration,
    type: message.level,
    style,
    key,
    btn: (
      <ButtonCustom
        size="small"
        danger={message.level === 'error'}
        type="text"
        style={{ color: FUNCTIONAL_COLORS[message.level] }}
        onClick={() => notification.destroy(key)}
        title={
          <span style={{ color: 'black' }}>
            {i18n.t('application.admin.messages.understand')}
          </span>
        }
      />
    ),
  });
};

export default Notify;
