import { isEqual } from 'lodash';
import { IModelTable } from 'common/models/interfaces/table';
import { updateTable } from 'common/reducers/table';

import { convertModelTableToLocalStorage } from 'common/utils/table';
import LocalStorageUtils from 'common/utils/local-storage';
import { USER_TABLE_OPTIONS } from 'common/constants/cookies-keys';
import { TIME_WEEK } from 'common/constants/time';
import { Dispatch } from 'redux';

const saveTableToLocalStorage = (state: Partial<IModelTable>) => {
  LocalStorageUtils.setItem(
    USER_TABLE_OPTIONS,
    JSON.stringify(convertModelTableToLocalStorage(state)),
    TIME_WEEK,
  );
};

export const updateAndPersistTable = (
  table: Partial<IModelTable>,
  dispatch: Dispatch,
) => {
  dispatch(
    updateTable({
      table: table,
    }),
  );
  saveTableToLocalStorage(table);
};

export const updateTableByValues =
  (table: IModelTable, newTableValues: Partial<IModelTable>) =>
  (dispatch: Dispatch) => {
    const newTable: Partial<IModelTable> = {};

    if (
      newTableValues.columns &&
      !isEqual(table.columns, newTableValues.columns)
    ) {
      newTable.columns = newTableValues.columns;
    }

    if (
      newTableValues.viewOptions &&
      !isEqual(table.viewOptions, newTableValues.viewOptions)
    ) {
      newTable.viewOptions = newTableValues.viewOptions;
    }

    if (!isEqual(table.fixHeader, newTableValues.fixHeader)) {
      newTable.fixHeader = newTableValues.fixHeader;
    }

    if (!isEqual(table.showBorder, newTableValues.showBorder)) {
      newTable.showBorder = newTableValues.showBorder;
    }

    if (!isEqual(table.paginate, newTableValues.paginate)) {
      newTable.paginate = newTableValues.paginate;
    }

    if (
      newTableValues.sortBy &&
      !isEqual(table.sortBy, newTableValues.sortBy)
    ) {
      newTable.sortBy = newTableValues.sortBy;
    }

    if (
      newTableValues.aggregation &&
      !isEqual(table.aggregation, newTableValues.aggregation)
    ) {
      newTable.aggregation = newTableValues.aggregation;
    }

    updateAndPersistTable(newTable, dispatch);
  };
