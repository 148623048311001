import i18next from 'i18next';
const i18n = i18next;
import { VpStoreIcon } from '@vtmn-play/icons/react';

import { generateParams } from 'common/utils/navigation';
import ModelToolbar from 'common/models/ModelToolbar';
import ModelFilters from 'common/models/ModelFilters';
import { IModelTable } from 'common/models/interfaces/table';
import { IPageParams } from 'common/models/interfaces/popup-selector';

const getLinkBySelectors = (
  filters: ModelFilters,
  table: IModelTable,
  toolbar: ModelToolbar,
  pageParams: IPageParams,
) => {
  let link = '/stores';

  link += generateParams(filters, table, toolbar, pageParams);

  return link;
};

export default {
  title: () => i18n.t(`navigation.label.allstores`),
  id: 'stores',
  icon: <VpStoreIcon style={{ marginLeft: '-3px', fontSize: '3rem' }} />,
  order: 3,
  link: getLinkBySelectors,
  paths: ['/stores'],
  subLinks: null,
  isNotAccessibleForFranchised: false,
};
