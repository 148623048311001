import { TIME_DAY } from 'common/constants/time';

import Cookie from 'common/utils/cookie';
import Optional from 'common/models/types/optional';

export default class LocalStorageUtils {
  public static setItem(
    key: string,
    value: string,
    duration: number = TIME_DAY,
    isSecure: Optional<boolean> = false,
  ): void {
    let isSecureWithoutNullValue = isSecure;
    if (isSecure === null) {
      isSecureWithoutNullValue = window.location.protocol === 'https:';
    }

    Cookie.set(key, 'true', duration, isSecureWithoutNullValue);
    localStorage.setItem(key, value);
  }

  public static getItem(key: string): string {
    if (!Cookie.get(key)) {
      localStorage.removeItem(key);
      return null;
    }

    return localStorage.getItem(key);
  }

  public static getObjectItem<T>(key: string): T {
    const jsonItem: string = this.getItem(key);
    if (!jsonItem) {
      return null;
    }

    try {
      return JSON.parse(jsonItem) as T;
    } catch {
      this.removeItem(key);
      return null;
    }
  }

  public static clear() {
    localStorage.clear();
  }

  public static removeItem(key: string) {
    localStorage.removeItem(key);
    Cookie.remove(key);
  }
}
